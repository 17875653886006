import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CALENDAR,
  TYPE_TASK,
  BENCH,
  CREATE_ORDER_STATUS_MODAL,
  INCOMING
} from 'constants/index';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import ActionsDropdown from 'components/common/actions';
import AssetCreatorDrawer from 'components/assets-view/drawers/creator';
import CategoryCreatorModal from 'components/assets-view/modals/category-creator';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import { useModalContext } from 'components/app/modals-provider';

import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { getAssetsManagers } from 'store/assets';

import getHasOwnerRole from 'utils/get-has-owner-role';

import styles from './view-info.module.scss';

const Handbook = ({ subView }) => {
  const { t } = useTranslation(['Journals', 'Contacts']);

  const workspace = useSelector(getActiveWorkspace);
  const employee = useSelector(getUserEmployee);
  const managers = useSelector(getAssetsManagers);

  const [visibleAssetCreator, setVisibleAssetCreator] = useState(false);
  const [visibleCategoryCreator, setVisibleCategoryCreator] = useState(false);
  const [visibleTaskCreator, setVisibleTaskCreator] = useState(false);

  const isOwner = getHasOwnerRole(employee.roles);
  const isManager = managers.find(item => item.employee.id === employee.id);

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  const { showModal } = useModalContext();

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data: {
        defaultKind: INCOMING,
        withContractor: true
      }
    });

  const actions = [
    {
      title: t('JournalAssetAddJournalAction'),
      allow: workspace.permissions.createAsset && subView !== CALENDAR,
      onClick: () => setVisibleAssetCreator(true)
    },
    {
      title: t('NewJournalAddJournalAction'),
      allow: subView !== CALENDAR && (isManager || isOwner),
      onClick: () => setVisibleCategoryCreator(true)
    },
    {
      title: t('AddContactToFunnel', { ns: 'Contacts' }),
      allow: subView === CALENDAR,
      className: styles.pinkDot,
      onClick: onCreateOrderStatus
    },
    {
      title: t('AddTaskAction'),
      allow: subView === CALENDAR,
      className: styles.yellowDot,
      onClick: () => setVisibleTaskCreator(true)
    }
  ];

  return (
    <div className={styles.handbook}>
      <Typography.Title level={2} className={styles.title}>
        {t('JournalsHeading')}
      </Typography.Title>

      <ActionsDropdown
        actions={actions}
        overlayClassName={styles.dropdown}
        btnStyle={{ padding: 0 }}
      >
        <Button type="primary" disabled={isBench} className={styles.button}>
          <Icon type="plus" />
          {t('AddBtn')}
        </Button>
      </ActionsDropdown>

      <AssetCreatorDrawer
        visible={visibleAssetCreator}
        onClose={() => setVisibleAssetCreator(false)}
      />

      <CategoryCreatorModal
        visible={visibleCategoryCreator}
        onClose={() => setVisibleCategoryCreator(false)}
      />

      <TaskCreatorDrawer
        value={{
          kind: TYPE_TASK,
          isDisabledTypeSelect: true
        }}
        visible={visibleTaskCreator}
        onClose={() => setVisibleTaskCreator(false)}
      />
    </div>
  );
};

export default Handbook;
