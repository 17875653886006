import React from 'react';
import PropTypes from 'prop-types';

import { CREATE_ORDER_STATUS_MODAL } from 'constants/index';

// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';
import Button from 'components/common/button';
// import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { useModalContext } from 'components/app/modals-provider';

export const CreateOrderBtn = ({
  children,
  withCreatorModal,
  data,
  onClick,
  ...props
}) => {
  // const [visiblePhoneConfirmation, setVisiblePhoneConfirmation] = useState(
  //   false
  // );
  const { showModal } = useModalContext();

  // const employee = useSelector(getUserEmployee);
  // const workspace = useSelector(getActiveWorkspace);

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data
    });

  const handleClick = ({ event } = {}) => {
    if (event) {
      event.stopPropagation();
    }

    // if (!employee.isPhoneVerified && !workspace.isNotVerified) {
    //   return setVisiblePhoneConfirmation(true);
    // }

    if (onClick) {
      return onClick();
    }

    return onCreateOrderStatus();
  };

  return (
    <>
      <Button {...props} onClick={e => handleClick({ event: e })}>
        {children}
      </Button>

      {/* <PhoneConfirmationModal
        visible={visiblePhoneConfirmation}
        employee={employee}
        description={
          <>
            Подтверждение номера телефона необходимо <br /> для обеспечения
            безопасной работы наших <br /> пользователей
          </>
        }
        onClose={() => setVisiblePhoneConfirmation(false)}
        callback={onClick || openDrawer}
      /> */}
    </>
  );
};

CreateOrderBtn.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  withCreatorModal: PropTypes.bool
};

CreateOrderBtn.defaultProps = {
  onClick: undefined,
  data: {},
  withCreatorModal: false
};

export default CreateOrderBtn;
