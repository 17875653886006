import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Menu, Input, Dropdown, Alert } from 'antd';
import omit from 'lodash/omit';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { Translation, useTranslation } from 'react-i18next';

import { CountrySelect } from 'components/common/controls/custom-select/country-select';

import { fetchContactsCompaniesSearchLocal } from 'store/contacts-companies';
import {
  searchWorkspacesLocal,
  searchExternalCompanyLocal
} from 'store/workspace';

import styles from './company-select.module.scss';

const mapValue = (resultType, isNew) => value => ({
  value: {
    ...value,
    resultType,
    isNew
  },
  label: value.title
});

export const WORKSPACE = 'workspace';
export const EXTERNAL_COMPANY = 'external-company';
export const CONTACTS_COMPANY = 'contacts-company';

export const getCompanyData = ({ company, country } = {}) => {
  if (!company || !country) {
    return {};
  }

  switch (company.resultType) {
    case WORKSPACE:
      return { workspace: company.id };

    case EXTERNAL_COMPANY:
      return {
        companyTitle: company.title,
        companyVatNumber: company.vatNumber,
        companyAddress: company.address,
        companyFoundationDate: company.foundationDate,
        companyCountry: country.value
      };

    case CONTACTS_COMPANY:
      return { company: company.id };

    default:
      return {
        ...omit(company, ['isNew']),
        companyTitle: company.title,
        companyCountry: country.value
      };
  }
};

export const validator = (__, value, callback) => {
  if (!value.company) {
    return callback(
      <Translation ns="Errors">{t => t('ValidateCompanyEmpty')}</Translation>
    );
  }

  if (!value.company.exist === false) {
    return callback('');
  }

  return callback();
};

const CompanySelect = ({
  value = {},
  onChange,
  countryCodeByPhone,
  ...props
}) => {
  const dispatch = useDispatch();

  const [country, setCountry] = useState(value.country);
  const [focused, setFocused] = useState(false);
  const [search, setSearch] = useState('');
  const [resultsOpen, setResultsOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isNeedMoreCompanies, setIsNeedMoreCompanies] = useState(true);

  const { t } = useTranslation(['AddContact', 'Common']);

  useEffect(() => {
    const setFocusedFalse = () => setFocused(false);

    document.addEventListener('click', setFocusedFalse);

    return () => {
      document.removeEventListener('click', setFocusedFalse);
    };
  }, []);

  useEffect(() => {
    if (!focused) {
      setSearch((value.company || {}).title || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showResults = () => setResultsOpen(true);
  const hideResults = () => setResultsOpen(false);

  const findWorkspaces = query =>
    dispatch(searchWorkspacesLocal(query)).then(({ results: entries }) =>
      entries.map(mapValue(WORKSPACE, true))
    );

  const findExternal = query =>
    dispatch(searchExternalCompanyLocal(query)).then(entries =>
      entries.map(mapValue(EXTERNAL_COMPANY, true))
    );

  const handleFindExternal = async query => {
    try {
      setIsLoading(true);

      const result = await findExternal(query);

      setResults(result);
      setFocused(true);
      showResults();
      setIsNeedMoreCompanies(false);
    } finally {
      setIsLoading(false);
    }
  };

  const findContactsCompany = query =>
    dispatch(fetchContactsCompaniesSearchLocal(query)).then(({ entries }) =>
      entries.map(mapValue(CONTACTS_COMPANY))
    );

  const fetchCompanies = async () => {
    if (!country) {
      return;
    }

    setIsLoading(true);

    try {
      const { hasValidation, id } = (country || {}).label;

      let entries = [];
      let isExternalSearch = false;

      entries = await findContactsCompany({ search, country: id });

      if (!entries.length) {
        entries = await findWorkspaces({ search, country: id });
      }

      if (!hasValidation && search && !entries.length) {
        entries = [
          { value: { title: search, isNew: true }, label: search },
          ...entries
        ];
      }

      if (hasValidation && !entries.length) {
        entries = await findExternal({ query: search, country: id });
        isExternalSearch = true;
      }

      setIsNeedMoreCompanies(!isExternalSearch);
      setResults(entries);
      showResults();
    } catch (err) {
      hideResults();
    } finally {
      setIsLoading(false);
    }
  };

  const handleError = company => {
    if (company && 'isActive' in company && !company.isActive) {
      return setError(t('CompanyError'));
    }

    return setError(null);
  };

  const onCompanyChange = company => {
    handleError(company);
    hideResults();
    setSearch(get(company, 'title', ''));

    onChange({
      country,
      company
    });
  };

  const onCountryChange = newValue => {
    setCountry(newValue);
    onCompanyChange();

    onChange({
      country: newValue,
      company: value.company
    });
  };

  const onSearchChange = event => {
    hideResults();
    setSearch(event.target.value);

    if (isEmpty(event.target.value)) {
      onCompanyChange();
    }
  };

  useEffect(() => {
    if (!focused) hideResults();

    // eslint-disable-next-line
  }, [focused]);

  const menu = (
    <Menu
      style={{
        maxHeight: 250,
        overflow: 'auto',
        maxWidth: 562
      }}
    >
      {!!results.length &&
        !isLoading &&
        results.map(option => (
          <Menu.Item
            key={option.value.id}
            value={option.value}
            style={{ whiteSpace: 'unset' }}
            onClick={() => onCompanyChange(option.value)}
          >
            {option.label}
          </Menu.Item>
        ))}

      {!!results.length && !isLoading && isNeedMoreCompanies && (
        <Menu.Item style={{ backgroundColor: 'transparent' }}>
          <Button
            type="link"
            onClick={() =>
              handleFindExternal({
                query: search,
                country: (country || {}).label.id
              })
            }
            style={{
              height: 'auto',
              padding: 0,
              fontWeight: 400
            }}
          >
            {t('NotFoundCompanyBtn')}
          </Button>
        </Menu.Item>
      )}

      {!results.length && !isLoading && (
        <Menu.Item disabled style={{ color: 'inherit' }}>
          {t('CompanyNotFound', { ns: 'Common' })}
        </Menu.Item>
      )}
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu} visible={resultsOpen}>
        <div
          className={styles.root}
          data-qa="qa-to6qlm65kajsoha"
          onClick={event => {
            event.nativeEvent.stopImmediatePropagation();
            setFocused(true);
          }}
        >
          <CountrySelect
            className={styles.countrySelect}
            data-qa="qa-5crdc5hv601x6ig"
            value={country}
            onChange={onCountryChange}
            isClearable={false}
            countryCodeByPhone={value.company ? null : countryCodeByPhone}
            popoverProps={{
              onClick: hideResults
            }}
          />

          <Input
            placeholder={t('EnterCompanyName')}
            className={styles.companySelect}
            data-qa="qa-b18h2a1v1g1flxs"
            value={search}
            onChange={onSearchChange}
            onBlur={() => setTimeout(hideResults, 200)}
            onFocus={() => results.length && showResults()}
            onPressEnter={event => {
              event.preventDefault();
              fetchCompanies();
            }}
            autoComplete="nope"
            allowClear
            {...props}
          />

          {(!value.company || value.company.title !== search) && (
            <Button
              className={styles.searchButton}
              data-qa="qa-5r10xm84ix2f499"
              onClick={fetchCompanies}
              loading={isLoading}
              disabled={isEmpty(search)}
              type="primary"
            >
              {t('Find', { ns: 'Common' })}
            </Button>
          )}
        </div>
      </Dropdown>

      {error && (
        <Alert message={error} type="error" style={{ marginTop: 15 }} />
      )}
    </>
  );
};

export default CompanySelect;
