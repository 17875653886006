import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CREATE_ORDER_STEP,
  LEADS,
  OUTGOING,
  // ORDER_TYPES,
  SELECT_ORDER_TYPE_STEP,
  TITLE_BY_KIND,
  TYPE_ORDER,
  TYPE_ORDER_STATUS
  // TYPE_ORDER_TEMPLATE
} from 'constants/index';

import MainForm from 'components/orders-view/forms/main';
import { useUpdateTags } from 'components/common/tags';

import {
  // getActiveWorkspace,
  getUserEmployee
} from 'store/workspace';
import {
  clearOrderStatusesEntries,
  // createOrder,
  createOrderStatus,
  fetchOrderStatuses,
  fetchOrderStatusLocal
  // createTemplate,
  // updateTemplate
} from 'store/order-statuses';

// import useAmplitude from 'hooks/amplitude/use-amplitude';
// import useModalsService from 'services/modals';

import SelectType from './select-type';

const useCreator = ({
  defaultStep = CREATE_ORDER_STEP,
  defaultKind = OUTGOING,
  defaultTab = TYPE_ORDER,
  defaultValues = {},
  // withShowDetails = true,
  visible = false,
  creatableCallback,
  closableCallback = () => {},
  onClose
}) => {
  const dispatch = useDispatch();
  const updateTags = useUpdateTags();
  // const amplitude = useAmplitude();
  // const modals = useModalsService();

  const [step, setStep] = useState(defaultStep);
  const [kind, setKind] = useState(defaultKind);
  const [tab, setTab] = useState(defaultTab);
  const [values, setValues] = useState(defaultValues);
  const [isLoading, setIsLoading] = useState(false);

  const [verifyModalData, setVerifyModalData] = useState(undefined);
  const [phoneConfirmationData, setPhoneConfirmationData] = useState(undefined);

  // const workspace = useSelector(getActiveWorkspace);
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation('AddOrder');

  const title = useMemo(() => {
    if (step === SELECT_ORDER_TYPE_STEP) {
      return t('AddOrderHeading');
    }

    if (values.isFromTemplate) {
      return ''; // NOT USED NOW
    }

    return t((TITLE_BY_KIND[kind] || {}).label);
  }, [kind, step, t, values.isFromTemplate]);

  const onSelectType = selectedType => {
    setKind(selectedType);
    setStep(CREATE_ORDER_STEP);
  };

  const handleClose = () => {
    onClose();
    closableCallback();
  };

  const onCreateFromTemplate = useCallback(data => {
    setValues({
      ...data,
      contact: data.contact || data.workspace,
      isToWorkspace: !!data.workspace,
      isFromTemplate: true
    });

    onSelectType(data.type);
  }, []);

  const onSubmit = async ({ isVerified, isPhoneVerified, ...value }) => {
    const {
      initialTags,
      isFromTemplate,
      asTemplate,
      tags,
      ...orderValues
    } = value;

    // if (
    //   (isVerified || !workspace.isNotVerified) &&
    //   (isPhoneVerified !== undefined
    //     ? !isPhoneVerified
    //     : !employee.isPhoneVerified)
    // ) {
    //   setPhoneConfirmationData(value);
    //   return;
    // }

    try {
      setIsLoading(true);

      const { identifier } = await dispatch(
        createOrderStatus({ values: { ...orderValues, kind } })
      );

      await updateTags({
        entityType: TYPE_ORDER_STATUS,
        entityId: identifier,
        newTags: tags
      });

      // if (asTemplate) {
      //   const template = await dispatch(
      //     isFromTemplate
      //       ? updateTemplate({
      //           id: values.id,
      //           order: { ...orderValues, relations: [] }
      //         })
      //       : createTemplate({
      //           order: { ...orderValues, relations: [] }
      //         })
      //   );

      //   await updateTags({
      //     entityType: TYPE_ORDER_TEMPLATE,
      //     entityId: template.id,
      //     initialTags,
      //     newTags: tags
      //   });
      // }

      // amplitude.addOrderEvent({
      //   ...orderValues
      //   isOffline: orderValues.isMarketplace
      //     ? false
      //   : !orderValues.workspace && !contact.employee
      // });

      if (creatableCallback) {
        const orderStatus = await dispatch(
          fetchOrderStatusLocal({ id: identifier })
        );
        creatableCallback(orderStatus);
      }

      // CHANGE AND CLEAN UP
      // if (withShowDetails) {
      //   modals.orders.showDetails({
      //     orderStatusId: identifier
      //   });
      // }

      dispatch(clearOrderStatusesEntries(LEADS));
      dispatch(fetchOrderStatuses({ statuses: LEADS }));

      handleClose();
    } catch (error) {
      const { status } = error.response || {};

      if (status === 409) {
        setVerifyModalData(value);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (visible) {
      setKind(defaultKind);
      setStep(defaultStep);
      setValues(defaultValues);
    }

    if (!visible) {
      setStep(defaultStep);
      setKind(undefined);
      setTab(defaultTab);
      setValues({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const data = {
    [SELECT_ORDER_TYPE_STEP]: {
      component: (
        <SelectType
          activeTab={tab}
          onChangeTab={setTab}
          onClick={onSelectType}
          createFromTemplate={onCreateFromTemplate}
        />
      )
    },
    [CREATE_ORDER_STEP]: {
      component: (
        <MainForm
          defaultValues={values}
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      ),
      goBack: () => {
        setStep(SELECT_ORDER_TYPE_STEP);
        setValues(defaultValues);
      }
    }
  };

  return {
    ...data[step],
    title,
    employee,
    phoneModal: {
      visible: phoneConfirmationData !== undefined,
      onClose: () => setPhoneConfirmationData(undefined),
      callback: () =>
        onSubmit({
          ...phoneConfirmationData,
          isVerified: true
        })
    },
    verifyModal: {
      visible: verifyModalData !== undefined,
      onClose: () => setVerifyModalData(undefined),
      callback: () =>
        onSubmit({
          ...verifyModalData,
          isPhoneVerified: true
        })
    },
    handleClose
  };
};

export default useCreator;
