import React from 'react';
import { useSelector } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';

import {
  FUNNELS_SETTINGS,
  TESTID_ORDERS_VIEW_CREATE_BUTTON,
  TITLE_BY_KIND
} from 'constants/index';

import CreateOrderBtn from 'components/common/create-order-btn';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import Tooltip from 'components/common/tooltip';

import { getWorkspacePermissions } from 'store/workspace';
import { getOrderStatusesFilterKind } from 'store/order-statuses';

import useRoutesService from 'services/routes';

import styles from './view-info.module.scss';

export const Orders = () => {
  const permissions = useSelector(getWorkspacePermissions);

  const kind = useSelector(getOrderStatusesFilterKind);

  const routes = useRoutesService();

  const { t } = useTranslation('Orders');

  return (
    <div className={styles.orders}>
      <Typography.Title level={2} className={styles.title}>
        {t('FunnelsHeading')}
      </Typography.Title>

      <Tooltip
        title={!permissions.createOrder ? t('AddOrderBenchTip') : undefined}
      >
        <CreateOrderBtn
          type="primary"
          disabled={!permissions.createOrder}
          className={styles.button}
          data={{
            defaultKind: kind,
            withContractor: true
          }}
          data-testid={TESTID_ORDERS_VIEW_CREATE_BUTTON}
        >
          <Icon type="plus" />{' '}
          <Translation ns={TITLE_BY_KIND[kind].ns}>
            {translate => translate(TITLE_BY_KIND[kind].label)}
          </Translation>
        </CreateOrderBtn>
      </Tooltip>

      <Button
        type="secondary"
        className={styles.orderSettingsButton}
        onClick={() =>
          routes.toContacts({
            view: FUNNELS_SETTINGS
          })
        }
      >
        {t('FunnelsSettingsBtn')}
      </Button>
    </div>
  );
};

export default Orders;
