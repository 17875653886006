import qs from 'qs';

import { makeCancelableRequest } from 'utils/make-cancalable-request';

const transformEmployeesParams = ({
  isActive = true,
  offset,
  limit,
  search,
  rate = 0,
  category = [],
  roleType,
  department,
  headDepartment,
  exclude,
  excludeSelf,
  excludeBench,
  excludeReplaced,
  directDepartment,
  direct,
  isManager,
  directWithBench,
  workspaceControllers,
  id,
  withPermissions,
  lite,
  withSystem
}) => ({
  search,
  is_active: isActive,
  category,
  offset,
  limit,
  rate,
  department,
  head_department: headDepartment,
  direct_department: directDepartment,
  direct,
  role_type: roleType,
  is_manager: isManager,
  exclude,
  exclude_self: excludeSelf,
  exclude_bench: excludeBench,
  exclude_replaced: excludeReplaced,
  direct_with_bench: directWithBench,
  workspace_controllers: workspaceControllers,
  id,
  with_permissions: withPermissions,
  lite,
  with_system: withSystem
});

const transfromParamsToBody = params =>
  Object.keys(params).reduce((acc, curr) => {
    acc[curr] = Array.isArray(params[curr])
      ? params[curr].join(',') || undefined
      : params[curr];

    if (curr === 'department' && acc[curr]) {
      acc[curr] = `${acc[curr]}`;
    }

    return acc;
  }, {});

export default api => ({
  fetch: (id, workspaceId) =>
    api.get(`/v1/${workspaceId}/employees/${id}/`, { errorHandle: false }),

  fetchWithCancel: makeCancelableRequest(
    api,
    (fetch, { workspaceId, isPostMethod, ...restParams }) => {
      const data = transformEmployeesParams(restParams);

      if (!isPostMethod) {
        return fetch('get', `/v1/${workspaceId}/employees/`, {
          params: data,
          paramsSerializer(params) {
            return qs.stringify(params, { arrayFormat: 'comma' });
          }
        });
      }

      const bodyData = transfromParamsToBody(data);

      return fetch('post', `/v1/${workspaceId}/employees/list/`, bodyData);
    }
  ),

  fetchAll: ({ workspaceId, isPostMethod, ...restParams }) => {
    const data = transformEmployeesParams(restParams);

    if (!isPostMethod) {
      return api.get(`/v1/${workspaceId}/employees/`, {
        params: transformEmployeesParams(restParams),
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      });
    }

    const bodyData = transfromParamsToBody(data);

    return api.post(`/v1/${workspaceId}/employees/list/`, bodyData);
  },

  patch: (workspaceId, user) =>
    api.patch(`/v1/${workspaceId}/employees/${user.id}/`, user),

  create: (workspace, user) =>
    api.post(`/v1/${workspace}/employees/`, { workspace, user }),

  changePosition: (workspaceId, id, position) =>
    api.patch(`/v1/${workspaceId}/employees/${id}/`, { position }),

  delete: (workspaceId, id, transferTo) =>
    api
      .post(`/v1/${workspaceId}/employees/${id}/dismiss/`, {
        transferTo
      })
      .then(({ data }) => data),

  benchOwner: (workspaceId, transferTo) =>
    api
      .post(`/v1/${workspaceId}/employees/bench-owner/`, { transferTo })
      .then(({ data }) => data),

  dismissOwner: (workspaceId, transferTo) =>
    api
      .post(`/v1/${workspaceId}/employees/dismiss-owner/`, { transferTo })
      .then(({ data }) => data),

  changeAdmin: (workspaceId, newAdmin) =>
    api
      .post(`/v1/${workspaceId}/employees/change-admin/`, {
        newAdmin
      })
      .then(({ data }) => data),

  changeDepartment: ({
    workspaceId,
    id,
    newDepartmentId,
    newManagerId,
    reason
  }) =>
    api.post(`/v1/${workspaceId}/employees/${id}/change-department/`, {
      newDepartment: newDepartmentId,
      newManager: newManagerId,
      reason
    }),

  replaceEmployee: ({
    workspaceId,
    id,
    replacerId,
    reason,
    fromDate,
    toDate
  }) =>
    api
      .post(`/v1/${workspaceId}/employees/${id}/replacement/`, {
        replacer: replacerId,
        reason,
        fromDate,
        toDate
      })
      .then(({ data }) => data),

  dismissReplacement: ({ workspaceId, id, replacerId }) =>
    api
      .delete(`/v1/${workspaceId}/employees/${id}/replacement/${replacerId}/`)
      .then(() => ({ employeeId: id, replacementId: replacerId })),

  join: ({ workspaceId, ...departmentValues }) =>
    api
      .post(`/v1/${workspaceId}/employees/join/`, departmentValues)
      .then(({ data }) => data),

  transfer: workspaceId =>
    api.post(`/v1/${workspaceId}/employees/handle-registration/`),

  becomeOwner: (workspaceId, position) =>
    api
      .post(`/v1/${workspaceId}/employees/become-owner/`, { position })
      .then(({ data }) => data),

  setCurrency: ({ currency, workspaceId, userId }) =>
    api.put(`/v1/${workspaceId}/employees/${userId}/`, {
      currency
    }),

  fetchSettings: ({ workspaceId, id }) =>
    api
      .get(`/v1/${workspaceId}/employee-settings/${id}/`)
      .then(({ data }) => data),

  setSettings: ({ workspaceId, id, field, value, enableSms }) =>
    api
      .patch(`/v1/${workspaceId}/employee-settings/${id}/`, {
        [field]: value,
        enableSms_8: enableSms
      })
      .then(({ data }) => data),

  checkDismiss: (workspaceId, id) =>
    api.get(`/v1/${workspaceId}/employees/${id}/check-dismiss/`),

  sendVerificationCode: (workspaceId, phone) =>
    api.post(
      `/v1/${workspaceId}/employees/send-verification-code/`,
      { phone },
      { show400: false }
    ),

  acceptVerificationCode: (workspaceId, code) =>
    api.patch(
      `/v1/${workspaceId}/employees/accept-verification-code/`,
      { code },
      { show400: false }
    )
});
