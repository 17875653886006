import React from 'react';

export const BoxIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.93 6.41998L12.18 1.16998C12.1257 1.14109 12.0652 1.12598 12.0037 1.12598C11.9423 1.12598 11.8818 1.14109 11.8275 1.16998L2.0775 6.41998C2.01692 6.45136 1.96605 6.49867 1.93037 6.55682C1.89468 6.61497 1.87554 6.68175 1.875 6.74998V17.25C1.87518 17.3178 1.89376 17.3843 1.92876 17.4424C1.96376 17.5006 2.01387 17.5481 2.07375 17.58L11.8238 22.83C11.878 22.8589 11.9385 22.874 12 22.874C12.0615 22.874 12.122 22.8589 12.1763 22.83L21.9263 17.58C21.9861 17.5481 22.0362 17.5006 22.0712 17.4424C22.1062 17.3843 22.1248 17.3178 22.125 17.25V6.74998C22.1252 6.68252 22.1072 6.61626 22.0728 6.55818C22.0385 6.5001 21.9892 6.45236 21.93 6.41998ZM12 1.92748L20.9625 6.74998L18.3375 8.16373L9.68625 3.17248L12 1.92748ZM3.0375 6.74998L5.86125 5.24998L14.5087 10.2225L12 11.5725L3.0375 6.74998ZM8.91 3.58873L17.5613 8.57998L15.285 9.80623L6.6375 4.81123L8.91 3.58873ZM15.7838 10.3875L17.8237 9.29248V12.09L17.0363 11.31C16.9925 11.2665 16.9387 11.2345 16.8796 11.2168C16.8205 11.1991 16.758 11.1962 16.6975 11.2085C16.6371 11.2207 16.5806 11.2477 16.5331 11.287C16.4855 11.3263 16.4484 11.3767 16.425 11.4337L15.7838 12.9787V10.3875ZM2.625 7.37623L11.625 12.225V21.8737L2.625 17.025V7.37623ZM12.375 21.8737V12.225L15.0338 10.7925V14.865C15.0381 14.9489 15.0705 15.029 15.1259 15.0923C15.1812 15.1557 15.2562 15.1985 15.3388 15.2141C15.4214 15.2297 15.5069 15.217 15.5815 15.1782C15.656 15.1394 15.7154 15.0766 15.75 15L16.8975 12.2287L17.925 13.2487C17.9775 13.3016 18.0445 13.3377 18.1175 13.3523C18.1905 13.367 18.2663 13.3596 18.3351 13.331C18.4038 13.3024 18.4626 13.254 18.5037 13.192C18.5449 13.1299 18.5667 13.057 18.5662 12.9825V8.88748L21.3713 7.38748V17.025L12.375 21.8737Z"
      fill="currentColor"
      stroke="currentColor"
    />
  </svg>
);

export default BoxIcon;
