import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Tooltip } from 'antd';

import {
  CHANNEL_TYPES,
  WEBSITE,
  TELEGRAM,
  EMAIL,
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_VERIFIED,
  TYPE_CONTACT,
  CONTACT_CREATOR_DRAWER,
  WEBSITE_FORM,
  ONLINE_CHAT,
  MESSAGE_TEMPLATES_DRAWER,
  TYPE_TASK,
  ASSET_CREATOR_DRAWER,
  TASK_CREATOR_DRAWER,
  WHATSAPP,
  SENT_STATUS_MESSAGE,
  DELIVERY_STATUS_MESSAGE,
  NOT_DELIVERY_STATUS_MESSAGE,
  STATUS_ERROR_SENDING_NOT_REGISTERED,
  STATUS_ERROR_SENDING_NOT_PAID,
  REMIND_MESSAGE_MODAL,
  CREATE_ORDER_STATUS_MODAL,
  INCOMING
} from 'constants/index';

import {
  getIsComment,
  getIsContactMessage,
  getIsFirstGroupMessage,
  getIsGroupMessage,
  getIsLastGroupMessage,
  getIsLogMessage,
  getIsMyMessage,
  getIsHelloMessage,
  getIsFromOnlineChat,
  getChannelKindByMessageIsFrom,
  getIsNotificationMessage,
  getIsFromOtherWorkspaceMessage
} from 'components/contacts-view/utils';
import ActionsDropdown from 'components/common/actions';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import Emoji from 'components/common/new-editor/components/emoji';
import { MESSAGE_TEMPLATES_CREATE } from 'components/common/drawers/message-templates/constants';
import { convertLog } from 'components/common/comments/converters';
import { getKindSubTask } from 'components/tasks-view/view/drawers/components/create-subtask-modal';
import Icon from 'components/common/icon';
import { useModalContext } from 'components/app/modals-provider';

import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { editContact } from 'store/contacts';
import { setVisibleDrawer } from 'store/drawers';
import {
  deleteDraftMessage,
  resendExistingMessage,
  setHighlightingMessageReaction
} from 'store/operator';
import { fetchChannelsLocal } from 'store/channels';

import { config } from 'config';
import { useWebsocketOperatorContext } from 'providers';
import { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import styles from './message.module.scss';

const useMessage = ({
  entity,
  messages,
  onClickReply,
  entityType,
  onClickAnswer,
  checkAllowReaction,
  destination
}) => {
  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);
  const activeWorkspace = useSelector(getActiveWorkspace);

  const {
    account: { admin }
  } = activeWorkspace;

  const [visibleEmojiPopover, setVisibleEmojiPopover] = useState(false);
  const [visibleControlDropdown, setVisibleControlDropdown] = useState(false);
  const [changedFileList, setChangedFileList] = useState();

  const routes = useRoutesService({ returnUrl: true, withOrigin: true });
  const modals = useModalsService({ returnUrl: true });

  const socket = useWebsocketOperatorContext();
  const { showModal } = useModalContext();

  const { t } = useTranslation([
    'Contacts',
    'Dashboard',
    'Errors',
    'CommonChat'
  ]);

  const { prev, curr, next } = messages;

  const {
    payload,
    isFrom,
    kind,
    sender,
    text,
    createdAt,
    fileList,
    roomUuid,
    subject,
    textHtml,
    leads,
    recipients,
    parent,
    uuid,
    reactions,
    isPrivate,
    isDraft,
    isNotSended: isUnsent,
    sentBySms,
    status,
    isSending,
    failedStatusCode
  } = curr;

  const logContent = convertLog(text, routes, modals);

  const isMy = getIsMyMessage(payload, employee.id);
  const isComment = getIsComment(kind);
  const isLog = getIsLogMessage(kind);
  const isContactMessage = getIsContactMessage(
    kind,
    isFrom,
    payload,
    employee.id
  );
  const isHelloMessage = getIsHelloMessage(prev, curr, employee.id);
  const isNotificationSource = !!prev && getIsNotificationMessage(prev.kind);

  const isFromOnlineChat = getIsFromOnlineChat(isFrom);

  const isFirstGroupMessage = getIsFirstGroupMessage(prev, curr);
  const isGroupMessage = getIsGroupMessage(prev, curr);
  const isLastGroupMessage = getIsLastGroupMessage(curr, next);

  const channelType = CHANNEL_TYPES.find(
    ({ type }) => type === getChannelKindByMessageIsFrom(isFrom)
  );
  const contactChat = entity.chats.find(chat => chat.uuid === roomUuid) || {};

  const isTelegram = contactChat.channelKind === TELEGRAM;
  const isWebsite = contactChat.channelKind === WEBSITE;
  const isWebsiteForm = contactChat.channelKind === WEBSITE_FORM;
  const isEmail = contactChat.channelKind === EMAIL;
  const isOnlineChat = contactChat.channelKind === ONLINE_CHAT;
  const isWhatsApp = contactChat.channelKind === WHATSAPP;

  const isGptMessage = config.REACT_APP_AI_ASSISTANT_EMPLOYEE_ID === +payload;

  const isArchivedContact = entity.status === CONTACT_STATUS_ARCHIVED;

  const isSentMessage = status === SENT_STATUS_MESSAGE;
  const isDeliveryMessage = status === DELIVERY_STATUS_MESSAGE;
  const isNotDeliveryMessage = status === NOT_DELIVERY_STATUS_MESSAGE;

  const allowCreateOrder =
    entityType === TYPE_CONTACT && !isNotificationSource
      ? entity.status === CONTACT_STATUS_VERIFIED
      : true;
  const allowCreateMessageTemplate =
    text[0] && text[0].text && text[0].text.length;

  const isNotSended = (isUnsent && !isSending) || isNotDeliveryMessage;

  const senderData =
    isContactMessage && !isFromOnlineChat
      ? {
          ...entity,
          isContact: true,
          fullName: isFrom === EMAIL ? sender.fullName : getFullName(entity),
          username: sender.username
        }
      : sender;

  const memoFileList = useMemo(
    () =>
      (changedFileList || fileList || []).map(({ mimetype, ...file }) => ({
        ...file,
        mimeType: mimetype
      })),
    [changedFileList, fileList]
  );

  const needReadNotificationMessage = isNotificationSource && !prev.isRead;

  const attachmentActionsDeps = {
    isFromOtherWorkspace: getIsFromOtherWorkspaceMessage({
      message: curr,
      chats: entity.chats
    }),
    allowDeleteFrom: isMy,
    isPrivate,
    sendCopyToComment: true,
    entityType,
    entityId: entity.id,
    messageUuid: uuid
  };

  const changeFileList = fileId =>
    setChangedFileList(memoFileList.filter(file => file.fileId !== fileId));

  const onReadNotificationMessage = useCallback(
    () =>
      socket.readNotificationMessage({
        roomUuid: prev.roomUuid,
        messageUuid: prev.uuid,
        channelKind: getChannelKindByMessageIsFrom(prev.isFrom),
        channelUuid: prev.channelId,
        isRead: !prev.isRead
      }),
    [prev, socket]
  );

  const showAssetCreator = useCallback(
    () =>
      dispatch(
        setVisibleDrawer({
          drawer: ASSET_CREATOR_DRAWER,
          data: {
            relations: isNotificationSource
              ? []
              : transformValueToRelation(
                  [
                    {
                      value: entity.id,
                      label: entity
                    }
                  ],
                  entityType
                ),
            description: [{ text: `${sender.fullName}: ${logContent}` }],
            fileList
          }
        })
      ),
    [
      dispatch,
      isNotificationSource,
      entity,
      entityType,
      sender.fullName,
      logContent,
      fileList
    ]
  );

  const showTaskCreator = async (data = {}) => {
    const generatedUuid = crypto.randomUUID();

    let channel;

    if (!isOnlineChat) {
      const { results } = await dispatch(
        fetchChannelsLocal({ channelUuid: contactChat.channelUuid })
      );

      [channel] = results;
    }

    dispatch(
      setVisibleDrawer({
        drawer: TASK_CREATOR_DRAWER,
        data: {
          taskInfo:
            channel &&
            channel.config.slaConfig &&
            channel.config.slaConfig.isActive
              ? {
                  operatorSla: channel.config.slaConfig.operatorSla,
                  executorSla: channel.config.slaConfig.executorSla,
                  messageUuid: uuid,
                  messageText: (text[0] || {}).text || '',
                  uuid: generatedUuid,
                  issueLink: `${window.location.origin}/issues/${generatedUuid}`,
                  roomUuid,
                  roomId: contactChat.id,
                  channelKind: contactChat.channelKind,
                  contactId: entity.id
                }
              : undefined,
          dateEnd:
            channel &&
            channel.config.slaConfig &&
            channel.config.slaConfig.isActive
              ? Date.now() + channel.config.slaConfig.executorSla * 60 * 1000
              : undefined,
          relations: transformValueToRelation(
            [
              {
                value: entity.id,
                label: entity
              }
            ],
            entityType
          ),
          description: [{ text: `${sender.fullName}: ${logContent}` }],
          fileList,
          ...data
        }
      })
    );
  };

  const messageReaction = useCallback(
    async ({ code, isFromPicker }) => {
      if (isFromPicker) {
        setVisibleEmojiPopover(false);
      }

      if (checkAllowReaction) {
        const allowReaction = await checkAllowReaction();

        if (!allowReaction) {
          return;
        }
      }

      const emojiCode = code.toUpperCase();

      const myReaction = reactions.find(
        r => r.code === emojiCode && r.senderId === employee.userId
      );

      const data = {
        messageUuid: uuid,
        roomUuid,
        kind: myReaction
          ? socket.messageReactionKind.unset
          : socket.messageReactionKind.set,
        channelKind: contactChat.channelKind,
        channelUuid: contactChat.channelUuid,
        code: emojiCode
      };

      if (isFromPicker && myReaction) {
        dispatch(setHighlightingMessageReaction({ ...data, value: true }));

        setTimeout(
          () =>
            dispatch(setHighlightingMessageReaction({ ...data, value: false })),
          1000
        );

        return;
      }

      socket.messageReaction(data);

      if (needReadNotificationMessage) {
        onReadNotificationMessage();
      }
    },
    [
      checkAllowReaction,
      contactChat,
      dispatch,
      employee.userId,
      needReadNotificationMessage,
      onReadNotificationMessage,
      reactions,
      roomUuid,
      socket,
      uuid
    ]
  );

  const resendDraftMessage = useCallback(() => {
    const message = {
      ...curr,
      isSending: true,
      isNotSended: false,
      entityType,
      entityId: entity.id,
      channelUuid: curr.channelId || contactChat.channelUuid,
      channelKind: isNotDeliveryMessage ? contactChat.channelKind : curr.appId,
      uuid: isNotDeliveryMessage || isDraft ? uuid : undefined,
      status: undefined,
      isResend: isNotDeliveryMessage
    };

    if (isNotDeliveryMessage) {
      dispatch(resendExistingMessage({ uuid, roomUuid }));
    } else {
      dispatch(deleteDraftMessage({ uuid, entityType, entityId: entity.id }));
    }

    socket.sendMessage(message);
  }, [
    contactChat.channelKind,
    contactChat.channelUuid,
    curr,
    dispatch,
    entity.id,
    entityType,
    isDraft,
    isNotDeliveryMessage,
    roomUuid,
    socket,
    uuid
  ]);

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data: {
        defaultKind: destination.entityType === TYPE_CONTACT && INCOMING,
        defaultValues: {
          relations: isNotificationSource
            ? []
            : transformValueToRelation(
                [{ value: entity.id, label: entity }],
                entityType
              ),
          description: [
            {
              text: `${sender.fullName}: ${logContent}`
            }
          ],
          fileList,
          contractor:
            entityType === TYPE_CONTACT && !isNotificationSource
              ? entity
              : undefined
        },
        withContractor: destination.entityType !== TYPE_CONTACT
      }
    });

  const chatActions = useMemo(
    () =>
      [
        {
          icon: 'phone',
          text: t('RequestContactsBtn'),
          allow: isLastGroupMessage && (isTelegram || isWebsite),
          onClick: async () => {
            if (!entity.responsible) {
              await dispatch(
                editContact({
                  id: entity.id,
                  values: { responsible: employee.id }
                })
              );
            }

            socket.requestContactInfo({
              roomUuid,
              channelKind: contactChat.channelKind,
              entityId: entity.id,
              entityType: TYPE_CONTACT,
              channelUuid: contactChat.channelUuid
            });
          }
        },
        {
          icon: 'like',
          text: t('RequestFeedbackBtn'),
          allow: isLastGroupMessage && isTelegram,
          onClick: () =>
            socket.requestFeedback({
              roomUuid,
              channelKind: contactChat.channelKind,
              entityId: entity.id,
              entityType: TYPE_CONTACT,
              channelUuid: contactChat.channelUuid
            })
        }
      ].filter(a => a.allow),
    [
      t,
      isLastGroupMessage,
      isTelegram,
      isWebsite,
      entity.responsible,
      entity.id,
      socket,
      roomUuid,
      contactChat,
      dispatch,
      employee.id
    ]
  );

  const controlActions = useMemo(
    () => [
      {
        title: t('RemindAboutMessage', { ns: 'CommonChat' }),
        ns: 'Common',
        onClick: () =>
          showModal(REMIND_MESSAGE_MODAL, {
            sourceMessage: curr,
            sourceEntityId: destination.entityId,
            sourceEntityType: destination.entityType,
            sourceEntityTitle: destination.entity.title
          }),
        allow: true
      },
      {
        title: 'AddContactToFunnel',
        ns: 'Contacts',
        onClick: onCreateOrderStatus,
        allow: allowCreateOrder
      },
      {
        title: 'AddTask',
        ns: 'Common',
        onClick: () =>
          showTaskCreator(isNotificationSource ? { relations: null } : {}),
        allow: true
      },
      {
        title: 'AddSubtask',
        ns: 'Common',
        onClick: () =>
          showTaskCreator({
            parent: entity.id,
            maxDateEnd: entity.dateEnd,
            responsible: [entity.responsible].filter(Boolean),
            project: entity.project,
            kind: getKindSubTask(entity)
          }),
        allow: entityType === TYPE_TASK && !isNotificationSource
      },
      {
        title: 'AddAsset',
        ns: 'Common',
        onClick: showAssetCreator,
        allow: true
      },
      {
        title: 'AddContact',
        ns: 'Common',
        onClick: () =>
          dispatch(
            setVisibleDrawer({ drawer: CONTACT_CREATOR_DRAWER, data: {} })
          ),
        allow: true
      },
      {
        title: 'SaveAsAnswerTemplate',
        ns: 'Common',
        onClick: () =>
          dispatch(
            setVisibleDrawer({
              drawer: MESSAGE_TEMPLATES_DRAWER,
              data: {
                initialComponent: MESSAGE_TEMPLATES_CREATE,
                value: text[0]
              }
            })
          ),
        allow: allowCreateMessageTemplate
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      allowCreateOrder,
      entityType,
      isNotificationSource,
      allowCreateMessageTemplate,
      entity,
      dispatch,
      text
    ]
  );

  const allowReply =
    !isWebsiteForm &&
    contactChat.channelIsActive &&
    !isWhatsApp &&
    !isArchivedContact;

  const actions = useMemo(
    () =>
      [
        {
          allow: isNotificationSource,
          onClick: onClickAnswer,
          tooltip: t('WriteMessageTip', { ns: 'Dashboard' }),
          id: 'answer',
          iconType: 'message'
        },
        {
          allow: allowReply && !isDraft && !isNotSended,
          onClick: () =>
            onClickReply({
              message: curr,
              withRecipients: false,
              isEmail
            }),
          tooltip: t('ReplyTip', { ns: 'Dashboard' }),
          id: 'reply',
          iconType: 'reply'
        },
        {
          allow:
            allowReply &&
            isEmail &&
            !!(recipients || []).length &&
            !isDraft &&
            !isNotSended,
          onClick: () =>
            onClickReply({
              message: curr,
              withRecipients: true,
              isEmail
            }),
          tooltip: t('ReplyAllTip'),
          id: 'reply-to-all',
          iconType: 'reply-to-all'
        },
        {
          allow: isOnlineChat && !isDraft && !isNotSended,
          id: 'reaction',
          component: (
            <Emoji
              iconProps={{
                size: 20,
                className: styles.emoji,
                color: visibleEmojiPopover ? 'brand' : 'black-55'
              }}
              popoverProps={{
                trigger: 'click',
                visible: visibleEmojiPopover,
                onVisibleChange: setVisibleEmojiPopover
              }}
              onSelect={emoji =>
                messageReaction({ code: emoji.unified, isFromPicker: true })
              }
            />
          )
        },
        {
          allow: !isDraft && !isNotSended,
          id: 'control',
          component: (
            <ActionsDropdown
              actions={controlActions}
              btnStyle={{ marginTop: 6 }}
              dropdownProps={{
                visible: visibleControlDropdown,
                onVisibleChange: setVisibleControlDropdown
              }}
              afterSelectCallback={() => {
                setVisibleControlDropdown(false);

                if (needReadNotificationMessage) {
                  onReadNotificationMessage();
                }
              }}
            />
          )
        },
        {
          tooltip: t('Resend', { ns: 'Dashboard' }),
          id: 'resend',
          onClick: resendDraftMessage,
          iconType: 'reload',
          allow: isNotSended && !isSending && !isArchivedContact
        },
        {
          tooltip: t('CancelSending', { ns: 'Dashboard' }),
          id: 'cancel',
          onClick: () => {
            if (isNotDeliveryMessage) {
              socket.removeFailedMessage({
                roomUuid: curr.roomUuid,
                uuid: curr.uuid,
                channelId: contactChat.channelUuid,
                channelKind: contactChat.channelKind
              });
            } else {
              dispatch(
                deleteDraftMessage({ uuid, entityType, entityId: entity.id })
              );
            }
          },
          iconType: 'close-circle',
          buttonMood: 'negative',
          allow: isNotSended && !isSending
        }
      ].filter(a => a.allow),
    [
      allowReply,
      contactChat.channelKind,
      contactChat.channelUuid,
      controlActions,
      curr,
      dispatch,
      entity.id,
      entityType,
      isArchivedContact,
      isDraft,
      isEmail,
      isNotDeliveryMessage,
      isNotSended,
      isNotificationSource,
      isOnlineChat,
      isSending,
      messageReaction,
      needReadNotificationMessage,
      onClickAnswer,
      onClickReply,
      onReadNotificationMessage,
      recipients,
      resendDraftMessage,
      socket,
      t,
      uuid,
      visibleControlDropdown,
      visibleEmojiPopover
    ]
  );

  const getSourceInfoLabel = useCallback(() => {
    if (sentBySms) {
      return ` (${t('SentBySMS')})`;
    }

    return ` (${sender.username})`;
  }, [sender.username, sentBySms, t]);

  const getIconStatusMessage = useCallback(() => {
    if (isMy) {
      if (isSentMessage) {
        return (
          <Tooltip mouseEnterDelay={0.5} title={t('MessageSentTip')}>
            <Icon type="single-checkbox" size={16} color="black-55" />
          </Tooltip>
        );
      }

      if (isDeliveryMessage) {
        return (
          <Tooltip mouseEnterDelay={0.5} title={t('MessageDeliveryTip')}>
            <Icon type="double-checkbox" size={16} color="black-55" />
          </Tooltip>
        );
      }
    }

    return undefined;
  }, [isDeliveryMessage, isMy, isSentMessage, t]);

  const getStatusTextNotDeliveryMessage = useCallback(() => {
    if (isEmail) {
      return t('SendingEmailError', { ns: 'Errors' });
    }

    if (failedStatusCode === STATUS_ERROR_SENDING_NOT_REGISTERED) {
      if (isWhatsApp) {
        return t('WhatsAppNotRegistredError', { ns: 'Errors' });
      }
    }

    if (failedStatusCode === STATUS_ERROR_SENDING_NOT_PAID) {
      if (isWhatsApp) {
        return (
          <>
            {t('WhatsAppNotPaidErrorWithoutAdmin', {
              ns: 'Errors',
              value: `+${contactChat.channelSource}`
            })}

            <a
              href={routes.toUserWorkspace({
                employeeId: admin.id
              })}
              target="_blank"
              rel="noreferrer"
            >
              {admin.lastName} {admin.firstName}
            </a>
          </>
        );
      }
    }

    return t('SendingMessageError', { ns: 'Errors' });
  }, [admin, contactChat, failedStatusCode, isEmail, isWhatsApp, routes, t]);

  return {
    sender: senderData,
    text,
    createdAt,
    contactChat,
    fileList: memoFileList,
    subject,
    textHtml,
    isFrom,
    isMy,
    isLog,
    isComment,
    isFirstGroupMessage,
    isGroupMessage,
    isLastGroupMessage,
    isHelloMessage,
    isFromOnlineChat,
    chatActions,
    ChannelIcon: (channelType || {}).Icon,
    leads,
    recipients,
    parent,
    actions,
    visibleEmojiPopover,
    visibleControlDropdown,
    reactions,
    messageUuid: uuid,
    messageReaction,
    logContent,
    isPrivate,
    isDraft,
    isNotSended,
    isNotificationSource,
    getSourceInfoLabel,
    getIconStatusMessage,
    getStatusTextNotDeliveryMessage,
    isSending,
    isGptMessage,
    attachmentActionsDeps,
    changeFileList
  };
};

export default useMessage;
