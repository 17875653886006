import React from 'react';
import { Link } from 'react-router-dom';
import { Typography } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Tooltip from 'components/common/tooltip';

import useRoutesService from 'services/routes';

import Option from '../option';

import styles from './order-option.module.scss';

const { Paragraph, Text } = Typography;

export const OrderOption = ({
  option,
  className,
  isLinkToElement,
  objectId,
  ...props
}) => {
  const routes = useRoutesService({ returnUrl: true });

  const OrderOptionContent = () => (
    <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
      <Text className={styles.textPrimary}>ID {option.orderId}</Text>

      <Tooltip title={option.title}>
        <span className={isLinkToElement ? styles.textPrimary : ''}>
          {option.title}
        </span>
      </Tooltip>
    </Paragraph>
  );

  return (
    <Option
      className={classnames(className, styles.root)}
      title={option.title}
      contentClassName={styles.content}
      {...props}
    >
      {isLinkToElement ? (
        <Link
          to={
            routes.toNewOrderStatus({
              contactId: option.contractorId,
              orderStatusId: option.id || objectId
            })

            // CLEAN UP
            // modals.orders.showDetails({
            // orderStatusId: option.id || objectId
            // })
          }
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <OrderOptionContent />
        </Link>
      ) : (
        <OrderOptionContent />
      )}
    </Option>
  );
};

OrderOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.string,
    orderId: PropTypes.number,
    title: PropTypes.string
  }).isRequired,
  isLinkToElement: PropTypes.bool,
  objectId: PropTypes.string
};

OrderOption.defaultProps = {
  isLinkToElement: false,
  objectId: null
};

export default OrderOption;
