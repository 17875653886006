import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Skeleton, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_CONTACT,
  TYPE_CONTACT_COMPANY,
  TYPE_TEMPLATE,
  TYPE_WORKSPACE,
  TYPE_CHANNEL,
  TYPE_ASSET,
  TYPE_ORDER_STATUS
} from 'constants/index';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

import styles from './entities.module.scss';

const Item = ({ objectId, fullPath, title, type, ...props } = {}) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const { t } = useTranslation(['MyDrive', 'Common']);

  const tooltipTitle =
    type === TYPE_ASSET ? `${title} ${fullPath || ''}` : `${objectId} ${title}`;

  const currentTypeData = {
    [TYPE_TASK]: {
      type: t('TaskLinksFilters'),
      title: `ID ${objectId} ${title}`,
      link: `${routes.toTasks()}${modals.tasks.showDetails({ id: objectId })}`
    },
    // CHANGE AND CLEAN UP
    // [TYPE_ORDER]: {
    //   type: t('OrderLinksFilters'),
    //   title: `ID ${objectId} ${title}`,
    //   link: `${routes.toOrderStatuses()}${modals.orders.showDetails({
    //     orderStatusId: objectId
    //   })}`
    // },
    // [TYPE_ORDER_STATUS]: {
    //   type: t('OrderLinksFilters'),
    //   title: `${title}`,
    //   link: `${routes.toOrderStatuses()}${modals.orders.showDetails({
    //     orderStatusId: objectId
    //   })}`
    // },
    [TYPE_CONTACT]: {
      type: t('ContactLinksFilters'),
      title: `ID ${objectId} ${title}`,
      link: routes.toContact({ id: objectId })
    },
    [TYPE_CONTACT_COMPANY]: {
      type: t('Counterparty', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`,
      link: routes.toContractor(objectId)
    },
    [TYPE_TEMPLATE]: {
      type: t('Template', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_WORKSPACE]: {
      type: t('Company', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_CHANNEL]: {
      type: t('Channel', { ns: 'Common' }),
      title: `ID ${objectId} ${title}`
    },
    [TYPE_ASSET]: {
      type: t('AssetLinksFilters'),
      title: `${title} ${fullPath || ''}`,
      link: `${routes.toAssets()}${modals.assets.showDetails({ id: objectId })}`
    }
  };

  if (!currentTypeData[type]) {
    return (
      <Tooltip title={tooltipTitle}>
        <div {...props} className={styles.item}>
          <Typography.Text ellipsis>{title}</Typography.Text>
        </div>
      </Tooltip>
    );
  }

  return (
    <Tooltip title={tooltipTitle}>
      <div {...props} className={styles.item}>
        <Typography.Text style={{ marginRight: 4 }}>
          {currentTypeData[type].type}
        </Typography.Text>

        {currentTypeData[type].link ? (
          <Link to={currentTypeData[type].link} className={styles.link}>
            {currentTypeData[type].title}
          </Link>
        ) : (
          <Typography.Text ellipsis>{title}</Typography.Text>
        )}
      </div>
    </Tooltip>
  );
};

const Menu = ({ items }) => (
  <div className={styles.menu}>
    {items.map(i => (
      <Item key={`${i.objectId}${i.title}`} {...i} className={styles.item} />
    ))}
  </div>
);

export const Entities = ({ items, orderStatuses }) => {
  const hasOrderStatuses = items.some(item => item.type === TYPE_ORDER_STATUS);

  const [results, setResults] = useState(items);

  const transformItems = () => {
    const transformedItems = items.reduce((acc, curr) => {
      if (curr.type === TYPE_ORDER_STATUS) {
        const orderStatus = orderStatuses.find(os => os.id === curr.objectId);

        acc.push({
          ...curr,
          ...(orderStatus || {})
        });
        return acc;
      }

      return acc;
    }, []);

    setResults(transformedItems);
  };

  useEffect(() => {
    if (hasOrderStatuses && orderStatuses) {
      transformItems();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOrderStatuses, orderStatuses]);

  if (hasOrderStatuses && !orderStatuses) {
    return (
      <Skeleton
        active
        paragraph={false}
        title={{
          width: '100%',
          className: styles.skeletonTitle
        }}
      />
    );
  }

  return (
    <Dropdown
      disabled={results.length < 2}
      overlay={<Menu items={results} />}
      trigger={['click']}
    >
      <div className={styles.root}>
        <Item {...results[0]} onClick={e => e.stopPropagation()} />

        {results.length > 1 && (
          <Icon
            color="black-55"
            type="arrow"
            size={16}
            className={styles.icon}
          />
        )}
      </div>
    </Dropdown>
  );
};

Entities.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      objectId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      title: PropTypes.string,
      type: PropTypes.string,
      fullPath: PropTypes.string
    })
  ),
  orderStatuses: PropTypes.array
};

Entities.defaultProps = {
  items: [],
  orderStatuses: null
};

export default Entities;
