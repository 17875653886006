import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { LEADS, TESTID_ORDERS_CREATOR_MODAL } from 'constants/index';

import Modal from 'components/common/modal';
import ShortForm from 'components/orders-view/forms/short';

import {
  clearOrderStatusesEntries,
  createOrderStatus,
  fetchOrderStatuses,
  fetchOrderStatus
} from 'store/order-statuses';

import useRoutesService from 'services/routes';

import styles from './creator.module.scss';

const OrderCreatorModal = ({ visible, data, onClose }) => {
  const { t } = useTranslation('Contacts');
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const { contactId } = useParams();

  const [isLoading, setIsLoading] = useState(false);

  const { creatableCallback, withShowDetails } = data;

  const onSubmit = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const { identifier } = await dispatch(
          createOrderStatus({ values: { ...values } })
        );

        if (creatableCallback) {
          const orderStatus = await dispatch(
            fetchOrderStatus({ id: identifier })
          );
          creatableCallback(orderStatus);
        }

        if (withShowDetails) {
          routes.toNewOrderStatus({
            contactId: (values.contractor && values.contractor.id) || contactId,
            orderStatusId: identifier
          });
        }

        dispatch(clearOrderStatusesEntries(LEADS));
        dispatch(fetchOrderStatuses({ statuses: LEADS }));

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId]
  );

  return (
    <Modal
      title={t('AddContactToFunnel')}
      visible={visible}
      onClose={onClose}
      destroyOnClose
      centered
      contentClassName={styles.root}
      width={508}
      dataTestId={TESTID_ORDERS_CREATOR_MODAL}
    >
      <ShortForm
        isCreate
        withContractor={data.withContractor}
        isLoading={isLoading}
        defaultKind={data.defaultKind}
        defaultValues={data.defaultValues}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

OrderCreatorModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.shape({
    creatableCallback: () => {},
    withShowDetails: PropTypes.bool,
    withContractor: PropTypes.bool,
    defaultKind: PropTypes.string,
    defaultValues: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

OrderCreatorModal.defaultProps = {
  visible: false,
  data: {
    withShowDetails: true
  }
};

export default OrderCreatorModal;
