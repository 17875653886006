import { createAction } from 'redux-actions';
import api from 'api';

import { ITEMS_PER_PAGE } from 'constants/pagination';
import { TYPE_CONTACT } from 'constants/index';

import createActionThunk from 'store/actions-thunk';
import { getWorkspaceId } from 'store/workspace';
import { fetchTagsByEntity } from 'store/tags';

import mapValue from 'utils/map-value';
import getOrderingValue from 'utils/get-ordering-value';

import { getFilterContacts, getContactsPageData } from './selectors';

export const fetchContacts = createActionThunk(
  'contacts/fetch',
  ({ getState, dispatch, searchKind, lite }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const {
      responsible,
      status,
      channel,
      channelKind,
      contactCompanyResponsible,
      tag,
      relations,
      search,
      isIndividual,
      createdDateRange,
      channelChat,
      isDeferred,
      funnelKind,
      funnelStatus
    } = getFilterContacts(state);

    const { orderStatus = [], task, contact, asset } = relations;

    const hasTags = !!tag.ids.length;
    const tagsCondition = tag.condition.value;
    const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

    const page = getContactsPageData(state);

    const funnelStatuses = (funnelStatus || []).map(x => {
      const value = mapValue(x);

      const lowerCasedValue = value.toLowerCase();

      return lowerCasedValue === 'leads' ? 'lead' : lowerCasedValue; // TODO: fix on back
    });

    return api.contacts
      .fetch({
        workspaceId,
        responsible: (responsible || []).map(mapValue),
        status: (status || []).map(mapValue),
        channel: (channel || []).map(mapValue),
        channelKind: (channelKind || []).map(mapValue),
        contactCompanyResponsible: (contactCompanyResponsible || []).map(
          mapValue
        ),
        isIndividual: (isIndividual || {}).value,
        createdDateRange: (createdDateRange || {}).value,
        search,
        limit: page.itemsPerPage,
        offset: page.offset,
        tagsCondition,
        tagsIds,
        orderStatus: orderStatus.map(mapValue),
        task: task.map(mapValue),
        contact: contact.map(mapValue),
        asset: asset.map(mapValue),
        channelChat: channelChat.map(chat => chat.id),
        searchKind,
        lite,
        isDeferred,
        funnelKind: (funnelKind || []).map(x => mapValue(x).toLowerCase()),
        funnelStatus: funnelStatuses
      })
      .then(({ data }) => {
        const contactIds = data.results.map(c => c.id);

        if (contactIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_CONTACT,
              entityIds: contactIds
            })
          );
        }

        return data;
      });
  }
);

export const fetchContactsLocal = createActionThunk(
  'contacts/fetch-local',
  ({
    isArchived = false,
    isIndividual,
    workspace,
    workspaceId,
    company,
    employee,
    status,
    sortDirection,
    sortField,
    limit,
    page,
    getState,
    search,
    offset,
    isOffline,
    exclude,
    lite
  }) => {
    const state = getState();
    const currentWorkspace = workspaceId || getWorkspaceId(state);
    const hasInputOffset = offset || offset === 0;
    const currentOffset = hasInputOffset ? offset : ITEMS_PER_PAGE * (page - 1);
    const currentLimit = limit || ITEMS_PER_PAGE;

    const ordering = getOrderingValue({ sortDirection, sortField });

    return api.contacts
      .fetch({
        workspaceId: currentWorkspace,
        company,
        workspace,
        isArchived,
        isIndividual,
        employee,
        ordering,
        status,
        limit: currentLimit,
        offset: currentOffset,
        search,
        isOffline,
        exclude,
        lite
      })
      .then(({ data }) => ({
        entries: data.results,
        totalItems: data.count
      }));
  }
);

export const fetchOneContact = createActionThunk(
  'contacts/fetchOne',
  ({ id, getState }) => {
    const state = getState();
    const workspace = getWorkspaceId(state);

    return api.contacts.fetchOne(id, workspace);
  }
);

export const fetchOneContactLocal = createActionThunk(
  'contacts/fetch-one-local',
  ({ id, getState }) => {
    const state = getState();
    const workspace = getWorkspaceId(state);

    return api.contacts.fetchOne(id, workspace);
  }
);

export const removeContact = createActionThunk(
  'contacts/remove',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.remove(workspaceId, id).then(() => id);
  }
);

export const fetchRecommendedContactsLocal = createActionThunk(
  'contacts/fetch-recommended-local',
  ({ getState }) => {
    const state = getState();
    const workspace = getWorkspaceId(state);

    return api.contacts.fetchRecommended(workspace);
  }
);

export const removeRecommendedContactLocal = createActionThunk(
  'contacts/remove-recommended-local',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.removeRecommended(workspaceId, id).then(() => id);
  }
);

export const createContact = createActionThunk(
  'contacts/create',
  ({ contact, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.create(workspaceId, contact).then(({ data }) => data);
  }
);

export const editContact = createActionThunk(
  'contacts/edit',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .edit(workspaceId, {
        id,
        ...values
      })
      .then(({ data }) => data);
  }
);

export const archiveContact = createActionThunk(
  'contacts/archive',
  ({ id, isDetailsView, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .archive(workspaceId, id)
      .then(() => ({ id, isDetailsView }));
  }
);

export const sendSmsInvite = createActionThunk(
  'contacts/send-sms-invite',
  ({ id, phone, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .sendSmsInvite(workspaceId, id, phone)
      .then(({ data }) => data);
  }
);

export const sendEmailInvite = createActionThunk(
  'contacts/send-email-invite',
  ({ id, email, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .sendEmailInvite(workspaceId, id, email)
      .then(({ data }) => data);
  }
);

export const fetchLinkInviteLocal = createActionThunk(
  'contacts/fetch-link-invite',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchLinkInvite(workspaceId, id)
      .then(({ data }) => data.link);
  }
);

export const fetchContactHistory = createActionThunk(
  'contacts/fetch-contact-history',
  ({ getState, id, offset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.fetchHistory({
      workspaceId,
      id,
      offset,
      excludeKind: null
    });
  }
);

export const sendContactComment = createActionThunk(
  'contacts/send-contact-comment',
  ({ getState, id, data }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.sendComment(workspaceId, id, data);
  }
);

export const restoreContact = createActionThunk(
  'contacts/restore',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.restore(workspaceId, id).then(({ data }) => data);
  }
);

export const fetchContactByChat = createActionThunk(
  'contacts/fetch-by-chat',
  ({ roomUuid, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .retrieveByChat(workspaceId, roomUuid)
      .then(({ data }) => data);
  }
);

export const fetchContactByChatLocal = createActionThunk(
  'contacts/fetch-by-chat-local',
  ({ roomUuid, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .retrieveByChat(workspaceId, roomUuid)
      .then(({ data }) => data);
  }
);

// MEMBERS (ACCESS SETTINGS)
export const fetchMembers = createActionThunk(
  'contacts/fetch-members',
  ({ getState, limit, offset, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts
      .fetchMembers({ limit, offset, id, workspaceId })
      .then(({ data }) => data.results);
  }
);

export const changeMembers = createActionThunk(
  'contacts/change-members',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts.members({ workspaceId, id, employees, isDelete });
  }
);

// RELATIONS
export const fetchRelations = createActionThunk(
  'contacts/fetch-relations',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts
      .fetchRelations(workspaceId, id)
      .then(({ data }) => data.results);
  }
);

export const changeRelations = createActionThunk(
  'contacts/change-relations',
  ({ getState, id, relations }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts.changeRelations({ workspaceId, id, relations });
  }
);

// MERGE
export const mergeContacts = createActionThunk(
  'contacts/merge',
  ({ getState, fromContact, toContact }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts.merge({ workspaceId, fromContact, toContact });
  }
);

export const fetchMergedContact = createActionThunk(
  'contacts/fetch-merged-contact',
  ({ getState, initialContact, fromContact, toContact }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.contacts.fetchOne(toContact, workspaceId).then(data => ({
      newContact: data,
      initialContact,
      fromContact,
      toContact
    }));
  }
);

export const toggleNotify = createActionThunk(
  'contacts/toggle-notification',
  ({ id, notify, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .toggleNotify({ workspaceId, id, notify })
      .then(({ data }) => data);
  }
);

export const createEmailChat = createActionThunk(
  'contacts/send-email',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .createEmailChat({ workspaceId, id, values })
      .then(({ data }) => data);
  }
);

export const createWhatsAppChat = createActionThunk(
  'contacts/create-whatsapp-chat',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .createWhatsAppChat({ workspaceId, id, values })
      .then(({ data }) => data);
  }
);

export const importContacts = createActionThunk(
  'contacts/import-contacts-upload',
  ({ values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .uploadImportContacts({ workspaceId, values })
      .then(({ data }) => data);
  }
);

export const fetchResultsImportContacts = createActionThunk(
  'contacts/fetch-results-import-contacts',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchResultsImportContacts({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchStatusImportContacts = createActionThunk(
  'contacts/fetch-results-import-contacts',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchStatusImportContacts({ workspaceId })
      .then(({ data }) => data);
  }
);

export const completeImportContacts = createActionThunk(
  'contacts/complete-results-import-contacts',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .completeImportContacts({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchStatusDuplicateContacts = createActionThunk(
  'contacts/fetch-status-duplicate-contacts',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchStatusDuplicateContacts({ workspaceId })
      .then(({ data }) => data);
  }
);

export const fetchResultsDuplicateContacts = createActionThunk(
  'contacts/fetch-results-duplicate-contacts',
  ({ getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchResultsDuplicateContacts({ workspaceId })
      .then(({ data }) => data);
  }
);

export const mergeDuplicateContact = createActionThunk(
  'contacts/merge-duplicate-contact',
  ({ getState, payload }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .mergeDuplicateContact({ workspaceId, payload })
      .then(({ data }) => data);
  }
);

export const markNotDuplicateContact = createActionThunk(
  'contacts/mark-not-duplicate-contact',
  ({ getState, payload }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .markNotDuplicateContact({ workspaceId, payload })
      .then(({ data }) => data);
  }
);

export const createDefer = createActionThunk(
  'contacts/create-defer',
  ({ getState, contactId, payload }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .createDefer({ workspaceId, contactId, payload })
      .then(({ data }) => data);
  }
);

export const removeDeferral = createActionThunk(
  'contacts/remove-deferral',
  ({ getState, contactId }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.removeDeferral({ workspaceId, contactId });
  }
);

export const addContactToEntries = createAction(
  'contacts/add-contact-to-entries'
);
export const setContactsIsLoading = createAction('contacts/set-is-loading');
export const editContactByRoomUuid = createAction(
  'contacts/edit-contact-by-room-uuid'
);

// FILTERS

export const setContactsFilterResponsible = createAction(
  'contacts/set-filter-responsible'
);
export const setContactsFilterStatus = createAction(
  'contacts/set-filter-status'
);
export const setContactsFilterChannel = createAction(
  'contacts/set-filter-channel'
);
export const setContactsFilterChannelKind = createAction(
  'contacts/set-filter-channel-kind'
);
export const setContactsFilterIsIndividual = createAction(
  'contacts/set-filter-is-individual'
);
export const setContactsFilterContactCompanyResponsible = createAction(
  'contacts/set-filter-contact-сompany-responsible'
);
export const setContactsFilterCreatedDateRange = createAction(
  'contacts/set-filter-created-date-range'
);
export const setContactsFilterTag = createAction('contacts/set-filter-tag');
export const setContactsFilterRelations = createAction(
  'contacts/set-filter-relations'
);
export const setContactsFilterSearch = createAction(
  'contacts/set-filter-search'
);
export const setContactsFilterDefer = createAction('contacts/set-filter-defer');
export const setContactsFilterFunnelKind = createAction(
  'contacts/set-filter-funnel-kind'
);
export const setContactsFilterFunnelStatus = createAction(
  'contacts/set-filter-funnel-status'
);

export const clearFilter = createAction('contacts/clear-filter');
export const clearEntries = createAction('contacts/clear-entries');

// PANELS

export const setCollapsedContactPanel = createAction(
  'contacts/set-collapsed-contact-panel'
);

export const setCollapsedContactsFilterPanel = createAction(
  'contacts/set-collapsed-contacs-filter-panel'
);
