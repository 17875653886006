import React from 'react';

import { ORDER_STATUS_KINDS } from 'constants/index';

import CustomSelect from '../custom-select';

const getOrderKinds = () =>
  ORDER_STATUS_KINDS.map(item => ({
    ...item,
    label: item.label,
    value: item.value
  }));

export const FunnelTypeSelect = props => {
  const { value } = props;

  return <CustomSelect value={value} options={getOrderKinds()} {...props} />;
};

export default FunnelTypeSelect;
