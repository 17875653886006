import themeVariables from 'theme';

export const STATUS_ALL = 'all';

export const DASHBOARD = 'dashboard';

export const ASSETS = 'assets';

export const SYSTEM = 'system';

export const CONTROLLERS = 'controllers';

export const CONTACT_INFO = 'contact-info';
export const SUBSCRIPTIONS = 'subscriptions';
export const REQUISITES = 'requisites';
export const PRICE_LIST = 'price-list';

export const PROJECTS = 'projects';
export const PROJECT = 'project';
export const PROJECT_ACTIVE = 'active';
export const PROJECT_COMPLETED = 'completed';
export const IDEAS = 'ideas';
export const IDEA = 'idea';
export const ACTIVE_IDEA = 'active';
export const PUBLIC_IDEA = 'archive';

export const SPRINTS = 'sprints';
export const SPRINT = 'sprint';
export const SPRINT_ACTIVE = 'active';
export const SPRINT_PLANNED = 'planned';
export const SPRINT_DELETED = 'deleted';
export const SPRINTS_COMPLETED = 'completed';

export const TASK = 'task';
export const ORDER = 'order';
export const CONTACT = 'contact';
export const ASSET = 'asset';
export const DEPARTMENT = 'department';

export const SPRINT_STATUSES = [
  {
    value: SPRINT_ACTIVE,
    label: 'ActiveSprintStatus',
    ns: 'Sprints',
    color: themeVariables.color.green
  },
  {
    value: SPRINT_PLANNED,
    label: 'PlannedSprintStatus',
    ns: 'Sprints',
    color: themeVariables.color.yellow
  },
  {
    value: SPRINTS_COMPLETED,
    label: 'ClosedSprintStatus',
    ns: 'Sprints',
    color: themeVariables.color['black-45']
  }
];

export const SPRINT_STATUS_OPTIONS = [
  { value: SPRINT_PLANNED, label: 'PlannedSprintStatus', ns: 'Sprints' },
  { value: SPRINT_ACTIVE, label: 'ActiveSprintStatus', ns: 'Sprints' },
  { value: SPRINTS_COMPLETED, label: 'ClosedSprintStatus', ns: 'Sprints' }
];

export const TASKS = 'tasks';
export const TEMPLATES = 'templates';
export const BACKLOG = 'backlog';
export const CALENDAR = 'calendar';

export const CALENDAR_ASSETS = 'calendar-assets';

export const REPORTS = 'reports';
// TODO: delete
export const TIME_TRACKING = 'time-tracking';
export const PERFROMING_DISCIPLINE = 'performing-discipline';
// export const REPORTS_STATUS_ORDERS = ORDERS;
export const REPORTS_STATUS_DEALS = 'deals';
export const EMPLOYEES = 'employees';
export const DEPARTMENTS = 'departments';
export const TEAM = 'team';
export const ORGSTRUCTURE = 'orgstructure';

export const ALL = 'all';

export const CATEGORIES = 'categories';
export const ATTACHMENTS = 'attachments';
export const BASKET = 'basket';

export const CONTACTS = 'contacts';

export const BILLING = 'billing';
export const SUPPORT = 'support';

export const SETTINGS = 'settings';
export const WORKSPACE_PROFILE = 'workspace-profile';
export const USER_PROFILE = 'user-profile';
export const SETTINGS_STATUS_PROFILE = 'user';
export const SETTINGS_STATUS_WORKSPACE = 'workspace';
export const SETTINGS_STATUS_RATING = 'rating';
export const SETTINGS_STATUS_NOTIFICATIONS = 'notifications';
export const SETTINGS_STATUS_SECURITY = 'security';
export const SETTINGS_STATUS_INTEGRATIONS = 'integrations';
export const SETTINGS_PERSONAL = 'personal';

export const REDIRECT = 'redirect';

export const TYPE_ORDER = 'order';
export const TYPE_ORDER_STATUS = 'order_status';
export const TYPE_ORDER_TEMPLATE = 'order_template';
export const TYPE_CONTACT = 'contact';
export const TYPE_CONTACT_COMPANY = 'contact_company';
export const TYPE_TEMPLATE = 'template';
export const TYPE_CHANNEL = 'channel';
export const TYPE_CHANNEL_CHAT = 'channel_chat';
export const TYPE_ATTACHMENT = 'attachment';
export const TYPE_EMPLOYEE = 'employee';
export const TYPE_PROJECT = 'project';
export const TYPE_ASSET = 'asset';
export const TYPE_MEMBERS = 'members';
export const TYPE_CONTROLLER = 'controller';
export const TYPE_CONTACT_TO = 'contactTo';
export const TYPE_DATE_START = 'dateStart';
export const TYPE_DATE_END = 'dateEnd';
export const TYPE_TIME = 'time';
export const TYPE_RELATIONS = 'relations';
export const TYPE_FILELIST = 'fileList';
export const TYPE_WORKSPACE = 'workspace';
export const TYPE_ADDRESS = 'address';
export const TYPE_ESTIMATION = 'estimation';
export const TYPE_LOCATION = 'location';
export const TYPE_TAGS = 'tags';
export const TYPE_CO_RESPONSIBLES = 'coResponsibles';
export const TYPE_APPROVING_MANAGER = 'approvingManager';
export const TYPE_COMPLETE_REQUIRE = 'completeRequire';
export const TYPE_MEETING_CONTACTS = 'meetingContacts';
export const TYPE_MESSAGES = 'messages';
export const TYPE_DEPARTMENT = 'department';
export const TYPE_REPORT = 'report';
export const TYPE_MESSAGE = 'message';
export const TYPE_DISCUSSION = 'discussion';

export const TITLE = 'title';
export const DESCRIPTION = 'description';

export const TYPE_CATEGORY = 'category';

export const ATTACHMENT_TYPE_ENTITY = 'entity';

// tags condition
const CONTAINS_ANY = 'contains_any';
const CONTAINS_ALL = 'contains_all';
const NOT_CONTAIN_ALL = 'not_contain_all';
export const UNTAGGED = 'untagged';
export const ANY = 'any';

export const CONTAINS_ANY_OPTION = {
  value: CONTAINS_ANY,
  label: 'ContainsAny',
  ns: 'Filters'
};

export const TAGS_CONDITION_OPTIONS = [
  CONTAINS_ANY_OPTION,
  {
    value: CONTAINS_ALL,
    label: 'ContainSelected',
    ns: 'Filters'
  },
  {
    value: NOT_CONTAIN_ALL,
    label: 'NotContainSelected',
    ns: 'Filters'
  },
  {
    value: UNTAGGED,
    label: 'NoTags',
    ns: 'Filters'
  },
  {
    value: ANY,
    label: 'ContainsAnyTag',
    ns: 'Filters'
  }
];

export const SENT_STATUS_MESSAGE = 'sent';
export const DELIVERY_STATUS_MESSAGE = 'delivery';
export const NOT_DELIVERY_STATUS_MESSAGE = 'not_delivery';

export const STATUS_ERROR_SENDING_DEFAULT = 1;
export const STATUS_ERROR_SENDING_NOT_REGISTERED = 2;
export const STATUS_ERROR_SENDING_NOT_PAID = 3;
