import React, { createContext, useContext, useState } from 'react';

import {
  IMPORT_CONTACTS_SUCCESS_MODAL,
  REASON_FOR_RETURN_ISSUE_MODAL,
  DEFER_DIALOG_MODAL,
  CHANGE_PAYMENT_SUBSCRIPTION_MODAL,
  CANCEL_PAYMENT_SUBSCRIPTION_MODAL,
  PAYMENT_MODAL_QUERY_PARAM,
  ADD_PAYMENT_ADDON_MODAL,
  CHANGE_PAYMENT_ADMIN_MODAL,
  RETRY_PAYMENT_MODAL,
  DELETE_WHATSAPP_NUMBER_MODAL,
  AMOUNT_DETAILS_MODAL,
  SERVICE_UNAVAILABLE_MODAL,
  WHATSAPP_SUCCESSFULLY_ADDED_MODAL,
  REMIND_MESSAGE_MODAL,
  REPLACE_TAG_MODAL,
  CREATE_TAG_MODAL,
  EDIT_TAG_MODAL,
  EDIT_ORDER_STATUS_MODAL,
  COPY_ORDER_STATUS_MODAL,
  CREATE_ORDER_STATUS_MODAL
} from 'constants/index';

import ImportContactsSuccessModal from 'components/contacts-view/import-contacts/modals/import-success';
import ReasonForReturnIssueModal from 'components/issues-view/modals/reason-for-return-issue';
import DeferDialogModal from 'components/contacts-view/modals/defer-dialog';
import ChangePaymentSubscriptionModal from 'components/settings-view/billing/modals/change-payment-subscription';
import CancelPaymentSubscriptionModal from 'components/settings-view/billing/modals/cancel-payment-subscription';
import PaymentFailedModal from 'components/settings-view/billing/modals/payment-failed';
import AddPaymentAddonModal from 'components/settings-view/billing/modals/add-payment-addon';
import ChangePaymentAdminModal from 'components/settings-view/billing/modals/change-payment-admin';
import RetryPaymentModal from 'components/settings-view/billing/modals/retry-payment';
import DeleteWhatsAppNumberModal from 'components/settings-view/billing/modals/delete-whatsapp-number';
import AmountDetailsModal from 'components/settings-view/billing/modals/amount-details';
import ServiceUnavailableModal from 'components/contacts-view/views/integrations/modals/service-unavailable';
import WhatsAppSuccessfullyAddedModal from 'components/settings-view/billing/modals/whatsapp-successfully-added';
import RemindMessageModal from 'components/contacts-view/views/chats/contact-chat/message/remind-message-modal';
import ReplaceTagModal from 'components/common/tags/replace-tag-modal';
import CreateTagModal from 'components/common/tags/create-tag-modal';
import EditTagModal from 'components/common/tags/edit-tag-modal';
import OrderChangeModal from 'components/orders-view/modals/change-record';
import OrderCopyModal from 'components/orders-view/modals/copy-record';
import OrderCreatorModal from 'components/orders-view/modals/creator';

import { useQueryParam } from 'hooks';
import useModalsService from 'services/modals';

const ModalContext = createContext();

export const useModalContext = () => useContext(ModalContext);

export const ModalsProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    name: null,
    props: null
  });

  const modals = useModalsService();

  const paymentSuccess = useQueryParam(PAYMENT_MODAL_QUERY_PARAM);

  const { name, props } = modalState;

  const showImportSuccessModal = name === IMPORT_CONTACTS_SUCCESS_MODAL;
  const showReturnReasonModal = name === REASON_FOR_RETURN_ISSUE_MODAL;
  const showDeferDialogModal = name === DEFER_DIALOG_MODAL;
  const showChangePaymentSubscriptionModal =
    name === CHANGE_PAYMENT_SUBSCRIPTION_MODAL;
  const showCancelPaymentSubscriptionModal =
    name === CANCEL_PAYMENT_SUBSCRIPTION_MODAL;
  const showAddPaymentAddonModal = name === ADD_PAYMENT_ADDON_MODAL;
  const showChangePaymentAdminModal = name === CHANGE_PAYMENT_ADMIN_MODAL;
  const showRetryPaymentModal = name === RETRY_PAYMENT_MODAL;
  const showDeleteWhatsAppNumbertModal = name === DELETE_WHATSAPP_NUMBER_MODAL;
  const showAmountDetailsModal = name === AMOUNT_DETAILS_MODAL;
  const showServiceUnavailableModal = name === SERVICE_UNAVAILABLE_MODAL;
  const showWhatsAppSuccessfullyAddedModal =
    name === WHATSAPP_SUCCESSFULLY_ADDED_MODAL;
  const showRemindMessageModal = name === REMIND_MESSAGE_MODAL;
  const showReplaceTagModal = name === REPLACE_TAG_MODAL;
  const showCreateTagModal = name === CREATE_TAG_MODAL;
  const showEditTagModal = name === EDIT_TAG_MODAL;
  const showCopyOrderStatusModal = name === COPY_ORDER_STATUS_MODAL;
  const showEditOrderStatusModal = name === EDIT_ORDER_STATUS_MODAL;
  const showCreateOrderStatusModal = name === CREATE_ORDER_STATUS_MODAL;

  const showModal = (modalName, modalProps) => {
    setModalState({
      name: modalName,
      props: modalProps
    });
  };

  const onCloseModal = () => {
    setModalState({
      name: null,
      props: null
    });
  };

  return (
    <>
      <ImportContactsSuccessModal
        visible={showImportSuccessModal}
        onClose={onCloseModal}
        {...props}
      />

      <ReasonForReturnIssueModal
        visible={showReturnReasonModal}
        onClose={onCloseModal}
        {...props}
      />

      <DeferDialogModal
        visible={showDeferDialogModal}
        onClose={onCloseModal}
        {...props}
      />

      <ChangePaymentSubscriptionModal
        visible={showChangePaymentSubscriptionModal}
        onClose={onCloseModal}
        {...props}
      />

      <CancelPaymentSubscriptionModal
        visible={showCancelPaymentSubscriptionModal}
        onClose={onCloseModal}
        {...props}
      />

      <PaymentFailedModal
        visible={paymentSuccess === false}
        onClose={() => modals.close()}
      />

      <AddPaymentAddonModal
        visible={showAddPaymentAddonModal}
        onClose={onCloseModal}
        {...props}
      />

      <ChangePaymentAdminModal
        visible={showChangePaymentAdminModal}
        onClose={onCloseModal}
        {...props}
      />

      <RetryPaymentModal
        visible={showRetryPaymentModal}
        onClose={onCloseModal}
        {...props}
      />

      <DeleteWhatsAppNumberModal
        visible={showDeleteWhatsAppNumbertModal}
        onClose={onCloseModal}
        {...props}
      />

      <AmountDetailsModal
        visible={showAmountDetailsModal}
        onClose={onCloseModal}
        {...props}
      />

      <ServiceUnavailableModal
        visible={showServiceUnavailableModal}
        onClose={onCloseModal}
        {...props}
      />

      <WhatsAppSuccessfullyAddedModal
        visible={showWhatsAppSuccessfullyAddedModal}
        onClose={onCloseModal}
        {...props}
      />

      <RemindMessageModal
        visible={showRemindMessageModal}
        onClose={onCloseModal}
        {...props}
      />

      <ReplaceTagModal
        visible={showReplaceTagModal}
        onClose={onCloseModal}
        {...props}
      />

      <CreateTagModal
        visible={showCreateTagModal}
        onClose={onCloseModal}
        {...props}
      />

      <EditTagModal
        visible={showEditTagModal}
        onClose={onCloseModal}
        {...props}
      />

      <OrderChangeModal
        visible={showEditOrderStatusModal}
        onClose={onCloseModal}
        {...props}
      />

      <OrderCopyModal
        visible={showCopyOrderStatusModal}
        onClose={onCloseModal}
        {...props}
      />

      <OrderCreatorModal
        visible={showCreateOrderStatusModal}
        onClose={onCloseModal}
        {...props}
      />

      <ModalContext.Provider value={{ modalState, showModal, onCloseModal }}>
        {children}
      </ModalContext.Provider>
    </>
  );
};
