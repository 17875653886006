import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import {
  fetchEmployee,
  fetchEmployees,
  deleteEmployee,
  setCurrentPage,
  setFilterCategories,
  setFilterSearch,
  setFilterRole,
  updateEmployee,
  fetchEmployeeOrder,
  addCategory,
  deleteCategory,
  clearFilter,
  replaceEmployeeAction,
  dismissReplacement
} from './actions';
import { makeSubscribeLocationChange } from '../../router';
import handleActions from '../../../utils/redux-actions';
import {
  ITEMS_PER_PAGE,
  ALL_OPTION,
  EMPLOYEES,
  TEAM,
  ASSET_MANAGER
} from '../../../constants';
import { updateWorkspaceUser } from '../../workspace';
import { deleteManager as deleteAssetManager } from '../../assets';
import { getInitialValueFilterStorage } from '../../../hooks/common/use-filter-storage';

export const initialFilter = {
  search: '',
  role: ALL_OPTION,
  categories: []
};

const initialState = {
  isLoading: false,
  isLoaded: false,

  currentPage: 1,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE,

  filter: getInitialValueFilterStorage(EMPLOYEES, initialFilter),

  entries: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(TEAM, EMPLOYEES),

    [fetchEmployees.START]: state => {
      state.isLoading = true;
      state.isLoaded = false;

      return state;
    },

    [fetchEmployees.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchEmployees.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchEmployees.SUCCEEDED]: (state, { payload }) => {
      state.isLoaded = true;
      state.totalItems = payload.totalItems;
      state.entries = payload.entries;

      return state;
    },

    [fetchEmployee.SUCCEEDED]: (state, { payload }) => {
      if (!payload.emptyRequest) {
        const index = state.entries.findIndex(e => e.id === payload.id);

        if (index !== -1) {
          state.entries[index] = payload;
        } else {
          state.entries = [...state.entries, payload];
        }
      }

      state.isLoaded = true;

      return state;
    },

    [fetchEmployeeOrder.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.entries.findIndex(item => item.id === args.employee);

      if (state.entries[index]) {
        state.entries[index].orders = payload.results;
      }

      return state;
    },

    [deleteEmployee.SUCCEEDED]: (state, { payload }) => {
      state.entries = state.entries.filter(item => item.id !== payload.id);
      state.totalItems -= 1;

      return state;
    },

    [replaceEmployeeAction.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        item => item.id === payload.replaced
      );

      if (index !== -1) {
        state.entries[index].replacements.push(payload);
      }

      return state;
    },

    [dismissReplacement.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(
        item => item.id === payload.employeeId
      );

      if (index !== -1) {
        state.entries[index].replacements = state.entries[
          index
        ].replacements.filter(r => r.id !== payload.replacementId);
      }

      return state;
    },

    [addCategory.SUCCEEDED]: (state, { payload, args }) => {
      const index = state.entries.findIndex(
        item => item.id === args.employeeId
      );

      if (state.entries[index]) {
        state.entries[index].categories = [
          ...state.entries[index].categories,
          payload.data
        ];
      }
      return state;
    },

    [deleteCategory.SUCCEEDED]: (state, { args }) => {
      const index = state.entries.findIndex(
        item => item.id === args.employeeId
      );

      if (state.entries[index]) {
        state.entries[index].categories = state.entries[
          index
        ].categories.filter(item => item.id !== args.categoryId);
      }

      return state;
    },

    [deleteAssetManager.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(item => item.id === payload);

      if (index !== -1) {
        state.entries[index].roles = state.entries[index].roles.filter(
          ({ type }) => type !== ASSET_MANAGER
        );
      }

      return state;
    },

    [combineActions(updateEmployee.SUCCEEDED, updateWorkspaceUser.SUCCEEDED)]: (
      state,
      { payload }
    ) => {
      const index = state.entries.findIndex(item => item.id === payload.id);

      if (state.entries[index]) {
        state.entries[index] = {
          ...state.entries[index],
          ...payload
        };
      }

      return state;
    },

    [combineActions(
      setFilterCategories,
      setFilterSearch,
      setFilterRole
    )]: state => {
      state.currentPage = 1;

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterCategories]: (state, { payload }) => {
      state.filter.categories = payload;

      return state;
    },

    [setFilterRole]: (state, { payload }) => {
      state.filter.role = payload;

      return state;
    },

    [clearFilter]: state => {
      state.filter = initialFilter;

      return state;
    }
  },
  initialState
);
