import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { LEADS } from 'constants/index';

import {
  clearOrderStatusesEntries,
  createOrderStatus,
  fetchOrderStatuses,
  fetchOrderStatusContractor,
  fetchOrderStatusSignatory,
  fetchOrderStatusAttachments,
  fetchOrderStatus
} from 'store/order-statuses';

import useRoutesService from 'services/routes';

const useCopy = ({ orderStatusId, contactId, visible, onClose }) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();

  const [isLoading, setIsLoading] = useState(false);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [values, setValues] = useState({});

  const fetchOrderData = useCallback(async () => {
    try {
      setFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const contractor = await dispatch(
        fetchOrderStatusContractor({ contractorId: orderStatus.contractorId })
      );

      const haveSignatory = !!orderStatus.signatoryId;

      let signatoryData = {};
      if (haveSignatory) {
        signatoryData = await dispatch(
          fetchOrderStatusSignatory({ signatoryId: orderStatus.signatoryId })
        );
      }

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: { relatedOrderStatuses: orderStatusId }
        })
      );

      setValues({
        ...orderStatus,
        contractor,
        signatory: haveSignatory && signatoryData,
        description: orderStatus.content,
        fileList: documentList,
        startAt: null,
        deadlineAt: null
      });
    } finally {
      setFetchLoading(false);
    }
  }, [dispatch, orderStatusId]);

  useEffect(() => {
    if (visible) {
      fetchOrderData();
    }
    return () => setValues({});
  }, [fetchOrderData, visible]);

  const onSubmit = useCallback(
    async newValues => {
      try {
        setIsLoading(true);

        const { identifier } = await dispatch(
          createOrderStatus({ values: { ...newValues, kind: newValues.kind } })
        );

        routes.toNewOrderStatus({
          contactId,
          orderStatusId: identifier
        });

        dispatch(clearOrderStatusesEntries(LEADS));
        dispatch(fetchOrderStatuses({ statuses: LEADS }));

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contactId]
  );

  const hasValues = !isEmpty(values) && !fetchLoading;

  return {
    isLoading,
    values,
    hasValues,
    onSubmit
  };
};

export default useCopy;
