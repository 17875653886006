import React from 'react';

export const ShoppingCardIcon = () => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21.6313 16.4508H7.67424L8.37502 15.0234L20.0188 15.0023C20.4125 15.0023 20.75 14.7211 20.8203 14.332L22.4328 5.30625C22.475 5.06953 22.4117 4.82578 22.2571 4.64062C22.1806 4.5495 22.0852 4.4761 21.9776 4.42551C21.8699 4.37492 21.7526 4.34835 21.6336 4.34766L6.82112 4.29844L6.69456 3.70312C6.61487 3.32344 6.27268 3.04688 5.88362 3.04688H2.26252C2.0431 3.04688 1.83266 3.13404 1.6775 3.2892C1.52235 3.44436 1.43518 3.65479 1.43518 3.87422C1.43518 4.09364 1.52235 4.30408 1.6775 4.45924C1.83266 4.6144 2.0431 4.70156 2.26252 4.70156H5.21331L5.76643 7.33125L7.12815 13.9242L5.37502 16.7859C5.28398 16.9088 5.22915 17.0547 5.21672 17.2071C5.20429 17.3596 5.23477 17.5124 5.30471 17.6484C5.44534 17.9273 5.72893 18.1031 6.04299 18.1031H7.51487C7.20108 18.5199 7.0316 19.0275 7.03206 19.5492C7.03206 20.8758 8.11018 21.9539 9.43674 21.9539C10.7633 21.9539 11.8414 20.8758 11.8414 19.5492C11.8414 19.0266 11.668 18.518 11.3586 18.1031H15.1344C14.8206 18.5199 14.6511 19.0275 14.6516 19.5492C14.6516 20.8758 15.7297 21.9539 17.0563 21.9539C18.3828 21.9539 19.461 20.8758 19.461 19.5492C19.461 19.0266 19.2875 18.518 18.9781 18.1031H21.6336C22.0883 18.1031 22.461 17.7328 22.461 17.2758C22.4596 17.0566 22.3716 16.8468 22.2162 16.6923C22.0607 16.5377 21.8505 16.4509 21.6313 16.4508ZM7.16565 5.92969L20.661 5.97422L19.3391 13.3758L8.7383 13.3945L7.16565 5.92969ZM9.43674 20.2898C9.02893 20.2898 8.69612 19.957 8.69612 19.5492C8.69612 19.1414 9.02893 18.8086 9.43674 18.8086C9.84455 18.8086 10.1774 19.1414 10.1774 19.5492C10.1774 19.7456 10.0993 19.934 9.96044 20.0729C9.82155 20.2118 9.63317 20.2898 9.43674 20.2898ZM17.0563 20.2898C16.6485 20.2898 16.3156 19.957 16.3156 19.5492C16.3156 19.1414 16.6485 18.8086 17.0563 18.8086C17.4641 18.8086 17.7969 19.1414 17.7969 19.5492C17.7969 19.7456 17.7189 19.934 17.58 20.0729C17.4411 20.2118 17.2527 20.2898 17.0563 20.2898Z"
      fill="currentColor"
    />
  </svg>
);

export default ShoppingCardIcon;
