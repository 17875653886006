import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';

import {
  STATUS_BACKLOG,
  STATUS_FOR_EXECUTION,
  TYPE_AGREEMENT,
  TYPE_MEETING,
  TYPE_ACQUAINTANCE,
  TYPE_AGREEMENT_TASK,
  TYPE_TASK,
  TYPE_TASK_TEMPLATE,
  ATTACHMENT_TYPE_ENTITY,
  TASK_FIELD_DATE_START,
  TASK_FIELD_FULL_LOCATION,
  TASK_FIELD_PROJECT,
  TASK_FIELD_COMPLETE_REQUIRE,
  TASK_FIELD_DESCRIPTION,
  INCOMING,
  CREATE_ORDER_STATUS_MODAL
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { DeleteTask } from 'components/common/icons';
import { useUpdateTags } from 'components/common/tags';
import {
  transformRelationsToSelectValue,
  transformValueToRelation
} from 'components/common/controls/custom-select/relations-select/utils';
import {
  checkAllowEditTask,
  checkIsChildTaskKindAcquaintence,
  checkIsChildTaskKindAgreement,
  checkIsChildTaskKindMeeting,
  checkIsTaskKindAcquaintence,
  checkIsTaskKindAgreement,
  checkIsTaskKindMeeting,
  checkIsTaskKindTask,
  checkIsTaskStatusCancelled,
  checkIsTaskStatusForExecution,
  checkIsTaskStatusInWork,
  checkIsTopTask
} from 'components/tasks-view/utils';
import { useModalContext } from 'components/app/modals-provider';

import {
  deleteTask,
  getSelectedTasks,
  updateStatus,
  createTemplateLocal,
  fetchAssignments,
  fetchRelations,
  fetchCoResponsibles,
  deleteTaskLocal,
  fetchAgreementSteps,
  fetchTaskLocal,
  fetchTask,
  convertSubtaskToTask,
  fetchAttachments,
  partialTaskUpdate
} from 'store/tasks';
import { getUserEmployee } from 'store/workspace';
import { fetchTagsByEntity } from 'store/tags';

import { useModalsService } from 'services/modals';
import { useRoutesService } from 'services/routes';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { showNoticeMessage } from 'services/notice';
import { useFieldEditorContext } from 'providers';

import ReasonModal from '../drawers/details/reason-modal';
import TaskEditorDrawer from '../drawers/editor';
import TaskListModal from '../../components/task-list-modal';

export const Actions = ({
  task,
  parentTask,
  isBacklog,
  isPreviewCard,
  setVisibleSprintSelect,
  isCalendarRecord,
  isSprintView,
  ...actionsProps
}) => {
  const modals = useModalsService();
  const routes = useRoutesService();

  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const updateTags = useUpdateTags();

  const selectedTasks = useSelector(getSelectedTasks);
  const { id: employeeId } = useSelector(getUserEmployee) || {};

  const [visibleDeleteModal, setVisibleDeleteModal] = useState(false);
  const [isLoadingDeleteTask, setIsLoadingDeleteTask] = useState(false);
  const [visibleReasonModal, setVisibleReasonModal] = useState(undefined);
  const [visibleCreatorDrawer, setVisibleCreatorDrawer] = useState(undefined);
  const [visibleEditorDrawer, setVisibleEditorDrawer] = useState(false);
  const [visibleActionsDropdown, setVisibleActionsDropdown] = useState(false);
  const [visibleTaskListModal, setVisibleTaskListModal] = useState(false);

  const { showModal } = useModalContext();

  const [isLoadingPermissions, setIsLoadingPermissions] = useState(false);

  const { t } = useTranslation([
    'Task',
    'Toast',
    'DeleteTask',
    'Common',
    'AddTask',
    'Contacts'
  ]);

  const { editableFields, edit, checkField } = useFieldEditorContext();

  const {
    id: taskId,
    status,
    workLog,
    workLogTotal,
    author,
    responsible,
    kind,
    taskInfo,
    dateStart,
    location,
    project,
    completeRequire,
    description
  } = task;

  const isTypeAcquaintence = checkIsTaskKindAcquaintence(kind);
  const isTypeMeeting = checkIsTaskKindMeeting(kind);
  const isTypeAgreement = checkIsTaskKindAgreement(kind);
  const isTypeTask = checkIsTaskKindTask(kind);

  const isTopTask = checkIsTopTask(task);

  const isChildAgreementTask = checkIsChildTaskKindAgreement({
    task,
    parentTask
  });
  const isChildAcquaintenceTask = checkIsChildTaskKindAcquaintence({
    task,
    parentTask
  });
  const isChildMeetingTask = checkIsChildTaskKindMeeting({ task, parentTask });

  const isUserAuthor = (author || {}).id === employeeId;
  const isUserResponsible = (responsible || {}).id === employeeId;

  const isStatusCancelled = checkIsTaskStatusCancelled(status);
  const isStatusExecution = checkIsTaskStatusForExecution(status);
  const isStatusInWork = checkIsTaskStatusInWork(status);

  const permissions = (task && task.permissions) || {};

  const allowDelete =
    permissions.deleteTask &&
    !(workLogTotal || workLog) &&
    !isStatusCancelled &&
    !taskInfo;

  const allowEdit = checkAllowEditTask({ task, parentTask });

  const allowToBacklog =
    (isStatusExecution || isStatusInWork) &&
    !!task.project &&
    !task.parent &&
    !isTypeAcquaintence &&
    permissions.changeTaskStatus;

  const isAllowSendToSprint =
    isBacklog && isTopTask && (isUserAuthor || permissions.changeTaskStatus);

  const allowDefer =
    isUserResponsible &&
    isStatusInWork &&
    !isTypeAgreement &&
    !isTypeAcquaintence &&
    !isTypeMeeting;

  const allowConvertTask =
    isTypeTask &&
    (permissions.changeSubtaskToTask || permissions.changeTaskToSubtask);

  const onSaveAsTemplate = async () => {
    const fetchedTask = await dispatch(fetchTaskLocal({ id: task.id }));

    const { results: assignments } = await dispatch(
      fetchAssignments({ directMembersOnly: true, id: task.id })
    );

    const _relations = await dispatch(fetchRelations({ id: task.id }));

    const { coResponsibles } = await dispatch(
      fetchCoResponsibles({ id: task.id })
    );

    const { [task.id]: tags } = await dispatch(
      fetchTagsByEntity({ entityType: TYPE_TASK, entityIds: [task.id] })
    );

    const template = await dispatch(
      createTemplateLocal({
        task: {
          ...fetchedTask,
          kind: task.approvingManager ? TYPE_AGREEMENT_TASK : fetchedTask.kind,
          project: task.project ? task.project.id : undefined,
          sprint: task.sprint ? task.sprint.id : undefined,
          approvingManager: (task.approvingManager || {}).id,
          responsible:
            isTypeAgreement || isTypeAcquaintence || isTypeMeeting
              ? undefined
              : [get(task, 'responsible.id')],
          coResponsibles: coResponsibles.map(({ id }) => id),
          controller: get(task, 'controller.id'),
          members: (assignments || []).map(({ employee }) => employee.id),
          relations: (_relations || []).map(({ objectId, type }) => ({
            relationId: objectId,
            relationType: type
          })),
          dateStart: undefined,
          dateEnd: undefined
        }
      })
    );

    updateTags({
      entityType: TYPE_TASK_TEMPLATE,
      entityId: template.id,
      newTags: (tags || []).map(tag => ({ value: tag.id, label: tag }))
    });

    amplitude.addTaskTemplate();

    showNoticeMessage({ customContent: t('TemplateSaved', { ns: 'Toast' }) });
  };

  const onCopy = async () => {
    const fetchedTask = await dispatch(fetchTaskLocal({ id: task.id }));

    const _responsible = Array.isArray(responsible)
      ? responsible
      : [responsible].filter(Boolean);

    const { results: members } = await dispatch(
      fetchAssignments({ directMembersOnly: true, id: task.id })
    );

    const { agreementSteps } = await dispatch(
      fetchAgreementSteps({ id: task.id })
    );

    const fetchedRelations = await dispatch(fetchRelations({ id: task.id }));

    const transformSteps = (agreementSteps || []).map(step => ({
      isEdit: true,
      employees: step.employees.map(e => ({ value: e.id, label: e }))
    }));

    const { coResponsibles } = await dispatch(
      fetchCoResponsibles({ id: task.id })
    );

    const { [task.id]: tags } = await dispatch(
      fetchTagsByEntity({ entityType: TYPE_TASK, entityIds: [task.id] })
    );

    const fileList = await dispatch(
      fetchAttachments({ id: task.id, source: ATTACHMENT_TYPE_ENTITY })
    );

    setVisibleCreatorDrawer({
      ...fetchedTask,
      fileList,
      tags,
      relations: transformRelationsToSelectValue(fetchedRelations),
      members,
      agreementSteps: transformSteps,
      dateEnd: undefined,
      dateStart: undefined,
      parent: null,
      responsible: _responsible,
      coResponsibles,
      isCopy: true,
      taskInfo: undefined
    });
  };

  const onSendWork = () => {
    const isSet = selectedTasks.includes(taskId);

    if (isSet) {
      return routes.toBacklog((task.project || {}).id);
    }

    return setVisibleSprintSelect({
      projectId: (task.project || {}).id,
      taskId
    });
  };

  const onConfirmDelete = async () => {
    try {
      setIsLoadingDeleteTask(true);

      await dispatch(
        deleteTaskLocal({
          id: taskId,
          projectId: (task.project || {}).id
        })
      );

      if (!isPreviewCard) {
        if (
          task.parent &&
          ![TYPE_MEETING, TYPE_AGREEMENT, TYPE_ACQUAINTANCE].includes(task.kind)
        ) {
          modals.tasks.showDetails({ id: task.parent });
        } else {
          modals.close();
        }
      }

      amplitude.deleteTaskEvent({
        ...task,
        author: task.author.id,
        responsible: (task.responsible || {}).id,
        controller: (task.controller || {}).id,
        approvingManager: (task.approvingManager || {}).id
      });

      dispatch(deleteTask({ id: taskId }));

      showNoticeMessage({ customContent: t('TaskDeleted', { ns: 'Toast' }) });
    } finally {
      setIsLoadingDeleteTask(false);
      setVisibleDeleteModal(false);
    }
  };

  const onUpdateStatus = async newStatus => {
    await dispatch(updateStatus(newStatus));

    modals.close();

    showNoticeMessage({ customContent: t('TaskCancelled', { ns: 'Toast' }) });
  };

  const dispatchSubtaskToBacklog = () =>
    Promise.all(
      task.children.map(subtask =>
        dispatch(updateStatus({ id: +subtask.id, status: STATUS_BACKLOG }))
      )
    );

  const onToBacklog = async () => {
    if (status === STATUS_BACKLOG) {
      await dispatch(
        partialTaskUpdate({ id: +taskId, values: { sprint: null } })
      );
    }

    await dispatch(updateStatus({ id: +taskId, status: STATUS_BACKLOG }));

    if ((task.children || []).length) {
      dispatchSubtaskToBacklog();
    }
  };

  const onDefer = () =>
    dispatch(updateStatus({ id: +taskId, status: STATUS_FOR_EXECUTION }));

  const onConvertToParentTask = async () => {
    await dispatch(convertSubtaskToTask({ taskId, parentId: task.parent }));

    await dispatch(fetchTask({ id: taskId }));

    showNoticeMessage();
  };

  const handleVisibleActionsDropdown = async visible => {
    if (!visible || !isEmpty(permissions)) {
      setVisibleActionsDropdown(visible);
      return;
    }

    try {
      setIsLoadingPermissions(true);

      await dispatch(fetchTask({ id: task.id }));
    } finally {
      setIsLoadingPermissions(false);
      setVisibleActionsDropdown(visible);
    }
  };

  const onCreateOrderStatus = () =>
    showModal(CREATE_ORDER_STATUS_MODAL, {
      data: {
        defaultKind: INCOMING,
        defaultValues: {
          relations: transformValueToRelation(
            [
              {
                value: taskId,
                label: task
              }
            ],
            TYPE_TASK
          )
        },
        withContractor: true
      }
    });

  const getActions = useCallback(
    () => [
      {
        title: t('AddFieldsBtn', { ns: 'Common' }),
        allow: allowEdit,
        children: [
          {
            title: t('Description', { ns: 'Common' }),
            icon: 'file-text',
            allow:
              !isTypeAgreement &&
              !isTypeAcquaintence &&
              !(description && description[0] && description[0].text) &&
              !checkField(TASK_FIELD_DESCRIPTION),
            onClick: () => edit({ field: TASK_FIELD_DESCRIPTION })
          },
          {
            title: t('AddressHeading', { ns: 'AddTask' }),
            icon: 'environment',
            allow:
              (isTypeTask || isTypeMeeting) &&
              !location &&
              !checkField(TASK_FIELD_FULL_LOCATION),
            onClick: () => edit({ field: TASK_FIELD_FULL_LOCATION })
          },
          {
            title: t(isTypeMeeting ? 'StartDateMeet' : 'StartDate', {
              ns: 'AddTask'
            }),
            icon: 'calendar',
            allow: !dateStart && !checkField(TASK_FIELD_DATE_START),
            onClick: () => edit({ field: TASK_FIELD_DATE_START })
          },
          {
            title: t('Project', { ns: 'AddTask' }),
            icon: 'project',
            allow: !project && !checkField(TASK_FIELD_PROJECT),
            onClick: () => edit({ field: TASK_FIELD_PROJECT })
          },
          {
            title: t('TaskResult', { ns: 'AddTask' }),
            icon: 'diff',
            allow:
              isTypeTask &&
              !completeRequire &&
              !checkField(TASK_FIELD_COMPLETE_REQUIRE),
            onClick: () => edit({ field: TASK_FIELD_COMPLETE_REQUIRE })
          }
        ]
      },
      {
        title: t('SaveTaskAsTemplateAction'),
        onClick: onSaveAsTemplate,
        allow:
          !isChildAgreementTask &&
          !isChildAcquaintenceTask &&
          !isChildMeetingTask
      },
      {
        title: t('EditTaskAction'),
        onClick: () => setVisibleEditorDrawer(true),
        allow: allowEdit
      },
      {
        title: t('MakeSubtaskAction'),
        onClick: () => setVisibleTaskListModal(true),
        allow: allowConvertTask && !task.parent
      },
      {
        title: t('DetachSubtaskAction'),
        onClick: onConvertToParentTask,
        allow: allowConvertTask && task.parent
      },
      {
        title: t('DeleteTaskAction'),
        onClick: () => setVisibleDeleteModal(true),
        allow: allowDelete
      },
      {
        title: t('CopyTaskAction'),
        onClick: onCopy,
        allow: true
      },
      {
        title: t('AddContactToFunnel', { ns: 'Contacts' }),
        onClick: onCreateOrderStatus,
        allow: true
      },
      {
        title: t('ToBacklog'),
        onClick: onToBacklog,
        allow: allowToBacklog
      },
      {
        title: t('PostponeTaskAction'),
        onClick: onDefer,
        allow: allowDefer
      },
      {
        title: t('BringTaskToWorkAction'),
        onClick: onSendWork,
        allow: isAllowSendToSprint
      },
      {
        title: t('RemoveTaskFromSprint'),
        onClick: onToBacklog,
        allow: isSprintView
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      task,
      editableFields.length,
      status,
      allowDelete,
      allowEdit,
      allowToBacklog,
      isAllowSendToSprint,
      isStatusCancelled,
      workLogTotal
    ]
  );

  return (
    <>
      <ActionsDropdown
        actions={getActions()}
        dropdownProps={{
          visible: visibleActionsDropdown,
          onVisibleChange: handleVisibleActionsDropdown
        }}
        isLoading={isLoadingPermissions}
        afterSelectCallback={() => handleVisibleActionsDropdown(false)}
        {...actionsProps}
      />

      <ModalDeleteConfirm
        visible={visibleDeleteModal}
        isLoading={isLoadingDeleteTask}
        title={t('DeleteTaskHeading', { ns: 'DeleteTask' })}
        description={t('DeleteTaskDesc', { ns: 'DeleteTask' })}
        deleteBtnText={t('DeleteBtn', { ns: 'DeleteTask' })}
        cancelBtnText={t('CancelBtn', { ns: 'DeleteTask' })}
        data-qa="qa-003694170708539"
        onConfirm={onConfirmDelete}
        iconModal={DeleteTask}
        onClose={() => setVisibleDeleteModal(false)}
      />

      <ReasonModal
        visible={visibleReasonModal !== undefined}
        data={visibleReasonModal}
        onUpdateStatus={onUpdateStatus}
        data-qa="qa-041874318889906"
        onClose={() => setVisibleReasonModal(undefined)}
      />

      <TaskCreatorDrawer
        visible={visibleCreatorDrawer !== undefined}
        value={visibleCreatorDrawer}
        onClose={() => setVisibleCreatorDrawer(undefined)}
      />

      <TaskEditorDrawer
        visible={visibleEditorDrawer}
        taskId={taskId}
        onClose={() => setVisibleEditorDrawer(false)}
      />

      <TaskListModal
        visible={visibleTaskListModal}
        onClose={() => setVisibleTaskListModal(false)}
        data={task}
      />
    </>
  );
};

Actions.propTypes = {
  task: PropTypes.object.isRequired,
  isBacklog: PropTypes.bool,
  isPreviewCard: PropTypes.bool,
  setVisibleSprintSelect: PropTypes.func
};

Actions.defaultProps = {
  isBacklog: false,
  isPreviewCard: false,
  setVisibleSprintSelect: () => {}
};

export default Actions;
