import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OrderCreatorModal from 'components/orders-view/modals/creator';

import { fetchOrderStatusesLocal } from 'store/order-statuses';

import CustomSelect from '../custom-select';
import OrderOption from '../custom-select/order-option';

const mapValue = value => ({
  value: value.id,
  label: value
});

export const OrderSelect = ({ isMulti, onChange, value, ...props }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('Common');
  const [visibleCreator, setVisibleCreator] = useState(false);

  const fetchData = params =>
    dispatch(fetchOrderStatusesLocal(params)).then(
      ({ entries, totalItems }) => ({
        entries: entries.map(mapValue),
        totalItems
      })
    );

  const addEntityButtonData = {
    title: t('AddOrder'),
    onClick: () => setVisibleCreator(true)
  };

  return (
    <>
      <CustomSelect
        isAsync
        isSearchable
        isClearable
        placeholder={t('SearchForOrders')}
        showSelectedOptionsOnList={false}
        fetchData={fetchData}
        Option={OrderOption}
        addEntityButtonData={addEntityButtonData}
        isMulti={isMulti}
        onChange={onChange}
        value={value}
        {...props}
      />

      <OrderCreatorModal
        visible={visibleCreator}
        data={{
          withShowDetails: false,
          creatableCallback: orderStatus => {
            const transfromed = mapValue(orderStatus);
            onChange(isMulti ? [...(value || []), transfromed] : transfromed);
          },
          withContractor: true
        }}
        onClose={() => setVisibleCreator(false)}
      />
    </>
  );
};

export default OrderSelect;
